import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const PageContainerTemplate = () => (
  <Box {...{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto' }}>
    <Box {...{ height: '100%', minWidth: 1200, position: 'relative' }}>
      <Outlet />
    </Box>
  </Box>
);
