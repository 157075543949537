import styled from "@emotion/styled"
import { createSvgIcon, Box, Link, Checkbox, Grid, Typography, Tooltip, Button } from "@mui/material"
import { Fragment } from "react"
import { useAppDispatch, useAppSelector } from "../../redux"
import { SectionId, selectOnLast, selectSectionRefMap, selectTaskRefMap } from "../../redux/task"
import { fetchRefresh, sendCheckTask } from "../../redux/task/taskDispatches"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CheckboxIcon = createSvgIcon(
    <>
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    <path fill="none" className="innerBox" d="M19 5v14H5V5h14" />
    </>,
  'CheckboxIcon'
)

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    height: '18px', 
    width: '18px', 
    marginRight: '4px',
    '& .MuiSvgIcon-root': { 
        fontSize: '18px',
    }, 
    
    '&.Mui-disabled:not(.Mui-checked)': {
        '& .MuiSvgIcon-root': { 
            fill: 'rgb(140, 140, 140)',
        }, 

        '& .MuiSvgIcon-root .innerBox': {
            fill: 'rgba(194, 194, 194, 1)',
        }
    },
}))

export const TaskSection = ({
    sectionId,
    setOpenFinalConfirmation,
    setFinalToSubmit,
    onFuture,
}:{
    sectionId: SectionId
    setOpenFinalConfirmation: React.Dispatch<React.SetStateAction<boolean>>; 
    setFinalToSubmit: React.Dispatch<React.SetStateAction<{ sectionId: string; taskId?: string | undefined; } | null>>; 
    onFuture?: boolean;
}) => {
    const dispatch = useAppDispatch()

    const taskRefMap = useAppSelector(selectTaskRefMap)
    const sectionRefMap = useAppSelector(selectSectionRefMap)
    const onLast = useAppSelector(selectOnLast)

    const {state, sectionName, tasks, description, refresh, tooltip} = sectionRefMap[sectionId]

    return <Grid container sx={{ width: '100%', bgcolor: '#FFFFFF', p: '18px 16px', borderRadius: '0 0 4px 4px', justifyContent: 'space-between', flexWrap: 'nowrap', '&:not(:last-of-type)': { borderBottom: '1px solid #D1DCE6' } }}>
        <Grid container alignItems="center" maxWidth="526px">
            {!tasks?.length && <CustomCheckbox checked={state.local} icon={<CheckboxIcon />} onClick={() => dispatch(sendCheckTask(sectionId))}/>}
            <Typography fontSize="12px" fontWeight="600">{sectionName}</Typography>
            {description && <Typography fontSize="12px" width="100%">{description}</Typography>}

            {
                tasks &&
                tasks.map((taskId) => {
                    const { state, taskName, id, link, subtasks, checkable } = taskRefMap[taskId]

                    return <Fragment key={id}>
                        <Grid container sx={{ p: '11px 0 0 8px', alignItems: 'flex-start', flexWrap: 'nowrap' }}>
                            <CustomCheckbox 
                            disabled={!checkable} 
                            onClick={
                                () => {
                                    if (!onFuture && onLast && !taskRefMap[taskId].state.local) {
                                        setFinalToSubmit({ sectionId, taskId })
                                        setOpenFinalConfirmation(true)
                                    } else {
                                        dispatch(sendCheckTask( sectionId, taskId ))
                                    }                                    
                                }
                            }
                            checked={state.local} 
                            icon={<CheckboxIcon />} 
                            />
                            {
                                link 
                                ? <Link href={link} target="_blank" underline="none"><Typography fontSize="12px">{taskName}</Typography></Link>
                                : <Typography fontSize="12px" dangerouslySetInnerHTML={{ __html: `${taskName}`.split(">").map((item, i, { length }) => i < length -1 ? [ `${item} style='color: rgba(0, 0, 0, 0.87);'>`] : item ).join("") }}></Typography>
                            }
                        </Grid>

                        {
                            subtasks && 
                            subtasks.map((subtaskId) => {
                                const { taskName, state, checkable } = taskRefMap[subtaskId]

                                return <Grid container alignItems="center" sx={{ p: '11px 0 0 20px' }} key={subtaskId}>
                                    <CustomCheckbox 
                                        disabled={!checkable} 
                                        onClick={
                                            () => {
                                                if (!onFuture && onLast && !taskRefMap[subtaskId].state.local) {
                                                    setFinalToSubmit({ sectionId, taskId: subtaskId })
                                                    setOpenFinalConfirmation(true)
                                                } else {
                                                    dispatch(sendCheckTask( sectionId, subtaskId ))
                                                }                                    
                                            }
                                        }
                                        checked={state.local}
                                        icon={<CheckboxIcon />} 
                                    />
                                    <Typography fontSize="12px" dangerouslySetInnerHTML={{ __html: `${taskName}`.split(">").map((item, i, { length }) => i < length -1 ? [ `${item} style='color: rgba(0, 0, 0, 0.87);'>`] : item ).join("") }}></Typography>
                                </Grid>
                            })
                        }
                    </Fragment>
                })
            }
        </Grid>

        <Box height="18px" width="max-content" display="flex" alignItems="center">
            {tooltip && !state.local && 
                <Tooltip 
                    title={tooltip} 
                    arrow 
                    componentsProps={{ 
                        tooltip: { 
                            sx: { 
                                bgcolor: '#FFFFFF', 
                                color: '#000000',
                                boxShadow: '0px 1px 8px 1px rgba(24, 67, 105, 0.2)',
                                fontSize: '12px',
                                fontWeight: 400
                                } 
                        },
                        arrow: {
                            sx: {
                                '&::before': { bgcolor: '#FFFFFF', boxShadow: '0px 1px 8px 1px rgba(24, 67, 105, 0.2)' }
                            }
                        }
                        }} 
                    sx={{ mr: '6px' }}>
                    <InfoOutlinedIcon color="primary"/>
                </Tooltip>
            }
            {
                state.local ? 
                <>
                    <CheckCircleIcon sx={{ color: 'rgba(102, 214, 63, 1)', mr: '6px' }}/>
                    <Typography fontWeight="600" fontSize="12px">Done</Typography>
                </>
                : refresh 
                    ? <Button 
                        variant="contained" 
                        onClick={() => dispatch(fetchRefresh(sectionId))} 
                        sx={{ width: '75px', fontSize: '12px' }}>
                            Refresh
                        </Button> 
                    : null
            }
        </Box>
    </Grid>
}