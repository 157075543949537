import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

const selectTaskState = createSelector(
  ({ task: taskState }: AppState) => taskState,
  (taskState) => taskState
);

export const selectCheckCompleteLoadingState = createSelector(
  selectTaskState,
  ({ checkCompleteLoadingState }) => checkCompleteLoadingState
)

export const selectTasksLoadingState = createSelector(
  selectTaskState,
  ({ tasksLoadingState }) => tasksLoadingState
)

export const selectRefreshLoadingState = createSelector(
  selectTaskState,
  ({ refreshLoadingState }) => refreshLoadingState
)

export const selectSendTaskLoadingState = createSelector(
  selectTaskState,
  ({ sendTaskLoadingState }) => sendTaskLoadingState
)

export const selectConfirmLoadingState = createSelector(
  selectTaskState,
  ({ confirmLoadingState }) => confirmLoadingState
)

export const selectTasksTree = createSelector(
  selectTaskState,
  ({ tasksTree }) => tasksTree
)

export const selectTaskRefMap = createSelector(
  selectTaskState,
  ({ taskRefMap }) => taskRefMap 
)

export const selectSectionRefMap = createSelector(
  selectTaskState,
  ({ sectionRefMap }) => sectionRefMap
)

export const selectDayOneSections = createSelector(
  selectTasksTree,
  ({ dayOneTasks }) => dayOneTasks 
)

export const selectWeekOneSections = createSelector(
  selectTasksTree,
  ({ weekOneTasks }) => weekOneTasks
)

export const selectFutureSections = createSelector(
  selectTasksTree,
  ({ futureTasks }) => futureTasks
)

export const selectTaskCounts = createSelector(
  selectDayOneSections,
  selectWeekOneSections,
  selectFutureSections,
  selectSectionRefMap,
  selectTaskRefMap,
  (dayOneSections, weekOneSections, futureSections, sectionRefMap, taskRefMap) => {

    const counts = {
      totalDayOne: dayOneSections.length,
      completedDayOne: dayOneSections.filter((item) => sectionRefMap[item].state.local).length,

      totalWeekOne: weekOneSections.length,
      completedWeekOne: weekOneSections.filter((item) => sectionRefMap[item].state.local).length,

      totalFuture: futureSections.length,
      completedFuture: futureSections.filter((item) => sectionRefMap[item].state.local).length,
    }

    return counts
  }
)

export const selectOnLast = createSelector(
  selectTaskState,
  ({ taskRefMap, sectionRefMap }) => {
    return Object.values({...taskRefMap, ...sectionRefMap}).filter((item) => !item.state.local).length <= 2
  }
)

export const selectNeedsCofirmation = createSelector(
  selectTaskState,
  selectTaskCounts,
  ({ allCompleted, tasksLoadingState, checkCompleteLoadingState }, counts) => {
    return !tasksLoadingState.dataFetching && !checkCompleteLoadingState.dataFetching && !allCompleted && counts.totalDayOne > 0 && counts.totalWeekOne > 0 && counts.completedDayOne === counts.totalDayOne && counts.completedWeekOne === counts.totalWeekOne
  }
)

export const selectCongratulationsOpen = createSelector(
  selectTaskState,
  ({ congratulationsOpen }) => congratulationsOpen
)