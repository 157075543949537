import getConnectedImg from '../../images/get_connected.jpg';
import getConnectedPh from '../../images/get_connected_ph.png';
import { DashboardButton } from './DashboardButton';

export const GetConnectedButton = () => (
  <DashboardButton
    {...{
      size: 'sm',
      link: '/get-connected',
      label: 'Get Connected',
      image: getConnectedImg,
      placeholder: getConnectedPh,
      aspectRatio: 1.74,
    }}
  />
);
