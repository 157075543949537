import { Grid, Typography, Link } from "@mui/material"
/**
 * You Said... We Did! panel for "Who We Are" page
 */
export const YouSaid = () => {
    return <Grid container p="72px 100px" bgcolor="#0089C7" color="#FFFFFF">
    <Typography fontSize="40px" fontWeight="700">You Said... We Did!</Typography>
    <Typography fontSize="18px">
    To keep our team members updated on the programs and initiatives implemented as a result of employee feedback, we established a quarterly email bulletin <Typography component="span" fontWeight="700">You Said…We Did!</Typography> In these newsletters, we outline how the team's feedback has a direct impact on the employee engagement programs we've launched and are currently working on. Stay tuned for our next Employer of Choice survey and quarterly updates!
    <br />
    <br />
    Check out our previous <Typography component="span" fontWeight="700">You Said…We Did!</Typography> bulletins <Link color="#FFFFFF" target="_blank" rel="noreferrer" href="https://myemail.constantcontact.com/You-Said----We-Did----Our-Journey-to-Become-an-Employer-of-Choice.html?soid=1126247715200&aid=EDOnw4baiho">here</Link>.
    <br />
    <br />
    To submit feedback <Link href="https://forms.office.com/r/2QXJkGRbQ5" color="#FFFFFF" target="_blank" rel="noreferrer">Click Here</Link>
    </Typography>
</Grid>
}