import { Grid, Typography } from '@mui/material';
import ourPurposeImg from '../../images/ourpurpose.jpg';
import ourPurposePh from '../../images/ourpurpose_ph.png';
import { ImageWithPlaceholder } from '../common/ImageWithPlaceholder';

/**
 * Our Purpose panel for "Who We Are" page
 */
export const OurPurpose = () => (
  <Grid container flexWrap="nowrap" position="relative" width="100%">
    <ImageWithPlaceholder
      {...{
        src: ourPurposeImg,
        alt: 'Our Purpose',
        placeholder: ourPurposePh,
        aspectRatio: 3.03,
        sx: { width: '100%', backgroundColor: '#022b40' },
      }}
    />

    <Grid
      container
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      textAlign="center"
      color="#FFFFFF"
      px="10%">
      <Typography fontSize="45px" fontWeight="700" fontFamily="Trade Gothic Bold" mb="10px">
        Our Purpose
      </Typography>

      <Typography fontFamily="Trade Gothic" mb="10px">
        There for you®, serving and safeguarding customers, communities, and people around the
        world.
      </Typography>

      <Typography fontFamily="Trade Gothic" maxWidth="950px">
        The success of our business is driven by our people. The success of our people is driven by
        our culture. Our culture is driven by our values. Living those values every day drives the
        success of our company. Our values are the backbone of our organization.
      </Typography>
    </Grid>
  </Grid>
);
