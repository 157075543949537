import { Grid, Typography } from "@mui/material"
import HeardAndListenedSVG from '../../images/heard_and_listened.svg'
import ICareSVG from '../../images/i_care.svg'
import CareerAdvancementSVG from '../../images/career_advancement.svg'
/**
 * Your Voice panel for "Who We Are" page
 */
export const YourVoice = () => {
    return <Grid container p="72px 100px" bgcolor="#FFFFFF">
    <Typography fontSize="40px" fontWeight="700" color="#0089C7">Your Voice</Typography>
    <Typography fontSize="18px" mb="28px">
    Here, at Allied Universal, our goal is to ensure that all employees feel that they have a voice and that their voice is heard. To better understand how employees feel about their experience at Allied Universal, we surveyed thousands of frontline and administrative team members across the organization. Based on the valuable feedback we received, we developed a comprehensive strategy to address the main areas needing improvement. The Employer of Choice focus areas listed below identify the opportunities for improvement highlighted in the survey feedback.
    </Typography>

    <Grid container width="max-content" margin="auto">
        <Grid container direction="column" alignItems="center" maxWidth="265px">
            <img src={HeardAndListenedSVG} alt="Heard and Listened"/>
            <Typography>
                Ensure employees feel <Typography component={'span'} fontWeight="700">heard</Typography> and <Typography component={'span'} fontWeight="700">listened</Typography> to within the company.
            </Typography>
        </Grid>

        <Grid container direction="column" alignItems="center" maxWidth="243px" mx="72px">
            <img src={ICareSVG} alt="I-Care"/>
            <Typography>
                Develop <Typography component={'span'} fontWeight="700">I-Care</Typography> leaders at every level of the organization.
            </Typography>
        </Grid>

        <Grid container direction="column" alignItems="center" maxWidth="250px">
            <img src={CareerAdvancementSVG} alt="Career Advancement"/>
            <Typography>
                Provide employees with <Typography component={'span'} fontWeight="700">Career Advancement</Typography> opportunities.
            </Typography>
        </Grid>
    </Grid>
</Grid>
}