import { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import { selectDidErrorOccur, selectIsLoggedIn } from '../../redux/auth';
import { checkComplete, fetchOnboardingTasks } from '../../redux/task/taskDispatches';
import { selectIntroUpdatingState, selectIsIntroCompleted } from '../../redux/user';
import { ErrorFallback } from '../error-boundary';
import { FiguringOutHome } from './FiguringOutHome';
import { LoginInProgress } from './LoginInProgress';

export const CheckLoginStatus = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const introUpdatingState = useAppSelector(selectIntroUpdatingState);
  const isIntroCompleted = useAppSelector(selectIsIntroCompleted);
  const didErrorOccur = useAppSelector(selectDidErrorOccur);
  const { pathname } = useLocation();

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isLoggedIn) {
      batch(() => {
        dispatch(checkComplete())
        dispatch(fetchOnboardingTasks())
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  return didErrorOccur ? (
    <ErrorFallback />
  ) : !isLoggedIn ? (
    <LoginInProgress />
  ) : introUpdatingState !== 'done' || (!isIntroCompleted && pathname !== '/welcome') ? (
    <FiguringOutHome />
  ) : (
    <Outlet />
  );
};
