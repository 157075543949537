import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { WideButton } from '../buttons/GenericButtons';

/**
 * Component implementing responsive error or warning dialog
 */
export const ErrorDialog = ({
  Icon = () => (
    <ErrorOutlineRoundedIcon
      {...{
        color: 'error',
        sx: { height: '100%', width: '100%' },
      }}
    />
  ),

  headline,
  message,
  open,
  onClose,
}: {
  /**
   * Component rendering an icon in the dialog title
   */
  Icon?: React.ComponentType;
  /**
   * Headline of the message
   */
  headline: string;
  /**
   * Message providing details
   */
  message: string;
  /**
   * Determines if the dialog is open
   */
  open: boolean;
  /**
   * Callback being called when the user clicks on Disagree button or tries to close the dialog
   * clicking the outside of it
   */
  onClose: () => void;
}) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'), { noSsr: true });

  return (
    <Dialog disablePortal {...{ open, onClose, fullScreen }}>
      <DialogTitle
        {...{
          sx: (theme) => ({
            textAlign: 'center',
            pt: '36px',
            px: '80px',
            [theme.breakpoints.down('md')]: { px: '26px' },
          }),
        }}>
        <Box
          {...{
            sx: (theme) => ({
              display: 'inline-block',
              height: 64,
              width: 64,
              [theme.breakpoints.down('md')]: { height: 48, width: 48 },
            }),
          }}>
          <Icon />
        </Box>

        <Typography
          {...{
            fontSize: '20px',
            fontWeight: 500,
            sx: (theme) => ({ pt: '8px', [theme.breakpoints.down('md')]: { pt: 0 } }),
          }}>
          {headline}
        </Typography>
      </DialogTitle>

      <DialogContent
        {...{
          sx: (theme) => ({
            pb: '36px',
            px: '80px',
            [theme.breakpoints.down('md')]: { px: '26px', flexGrow: 1 },
          }),
        }}>
        {message}
      </DialogContent>

      <DialogActions
        {...{
          sx: (theme) => ({ p: '36px 150px', [theme.breakpoints.down('md')]: { px: '26px' } }),
        }}>
        <WideButton {...{ onClick: onClose }}>OK</WideButton>
      </DialogActions>
    </Dialog>
  );
};
