import { PanelWithHeader } from "../PanelWithHeader"
import { LinkComponent } from "./LinkComponent"
import { RowItem } from "./RowItem"

/**
 * Pay and Expenses panel on Resources and Tools page
 */
export const PayAndExpenses = () => {
    return <PanelWithHeader header="Pay & Expenses" tooltipMessage="Please note that items listed below may not be applicable to all employees. Discuss with your manager prior to submitting requests." sx={{ maxWidth: '324px', height: 'max-content' }}>
        <RowItem>
            <LinkComponent header href="https://wap.aus.com/Courier/GeneralInquiry">Set Up Direct Deposit</LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://allieduniversalservices.sharepoint.com/:b:/r/sites/ss/Public%20Documents/Fuel%20Card%20Policy.pdf?csf=1&web=1&e=BqH2A4">Fuel Card</LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://support.aus.com/support/catalog/items/105">Corporate Credit Card</LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://www.finfit.com/employee/?ReturnURL=/WebForms/Tools/Default.aspx">
                Financial Planning Resources
            </LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://allieduniversalservices.sharepoint.com/sites/ss/Public%20Documents/AUS%20Travel%20Policy%202022.pdf">
                Travel Expense Policy
            </LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://support.aus.com/support/catalog/items/224">Expense Report Portal</LinkComponent>
        </RowItem>
    </PanelWithHeader>
}