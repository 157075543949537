import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../images/logo_allied_universal.png';
import { useAppSelector } from '../../redux';
import { selectIsLoggedIn } from '../../redux/auth';
import { selectIsIntroCompleted } from '../../redux/user';

/**
 * App header contains: Allied logo (navigates to home page) and app title
 */
export const AppHeader = () => {
  const { pathname } = useLocation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const isIntroCompleted = useAppSelector(selectIsIntroCompleted);

  return (
    <AppBar {...{ color: 'primary', position: 'absolute', sx: { height: 72, boxShadow: 'unset' } }}>
      <Toolbar {...{ component: Grid, container: true, disableGutters: true,  sx: { height: '100%' } }}>
        <Grid item xs={3}>
          <Button
            {...{
              disabled:
                !isLoggedIn || pathname === '/' || (pathname === '/welcome' && !isIntroCompleted),
              component: Link,
              to: '/',
            }}>
            <Box {...{ component: 'img', src: logo, alt: 'Allied Universal', width: '218.18px' }} />
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Typography {...{ sx: { color: '#FFFFFF', fontSize: 24, textAlign: 'center' } }}>
            Phenomenal Start Onboarding
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
