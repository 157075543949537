import { ErrorDialog } from "./ErrorDialog";

/**
 * Component for generic error
 */
export const GenericErrorDialog = ({
  open,
  onClose,
}: {
  /**
   * Determines if the dialog is open
   */
  open: boolean;
  /**
   * Callback being called when the user clicks on Disagree button or tries to close the dialog
   * clicking the outside of it
   */
  onClose: () => void;
}) => (
  <ErrorDialog
    {...{
      open,
      onClose,
      headline: 'Oops! Something went wrong.',
      message: 'The engineers have been notified. Please try again later.',
    }}
  />
);
