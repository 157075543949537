import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import envConfig from '../../config/envConfig.json';
import type { AuthState } from './auth.types';

const initialState: AuthState = {
  ...obtainEnvConst(),
  accessToken: null,
  isLoggedIn: false,
  isObtainingAccessTokenInProgress: false,
  rememberPathnameToReturnAfterLogin: null,
  didErrorOccur: false,
};

export const {
  reducer: authReducer,
  actions: { startObtainingToken, registerToken, logout, propagateError },
} = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    startObtainingToken: (state, { payload: pathnameToRemember }: PayloadAction<string | null>) => {
      state.isObtainingAccessTokenInProgress = true;
      state.rememberPathnameToReturnAfterLogin = pathnameToRemember;
    },

    registerToken: (state, { payload: accessToken }: PayloadAction<string>) => {
      state.accessToken = accessToken;
      state.isLoggedIn = true;
      state.isObtainingAccessTokenInProgress = false;
    },

    logout: (state) => {
      state.accessToken = null;
      state.isLoggedIn = false;
      state.isObtainingAccessTokenInProgress = false;
    },

    propagateError: (state) => {
      state.didErrorOccur = true;
    },
  },
});

export function obtainEnvConst() {
  const envConst = envConfig[window.location.hostname.toLowerCase() as keyof typeof envConfig];

  if (envConst == null) {
    throw Error('unknown environment');
  }

  return envConst;
}
