import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  obtainLogInStatus,
  selectIsObtainingAccessTokenInProgress,
  selectPathnameToSetAfterLogin,
} from '../../redux/auth';
import { Initializing } from './Initializing';

export const LoginInProgress = () => {
  const dispatch = useAppDispatch();
  const isObtainingAccessTokenInProgress = useAppSelector(selectIsObtainingAccessTokenInProgress);
  const pathnameToSetAfterLogin = useAppSelector(selectPathnameToSetAfterLogin);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(obtainLogInStatus(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isObtainingAccessTokenInProgress &&
      pathnameToSetAfterLogin != null &&
      pathname !== pathnameToSetAfterLogin &&
      navigate(pathnameToSetAfterLogin, { replace: true });
  }, [isObtainingAccessTokenInProgress, navigate, pathname, pathnameToSetAfterLogin]);

  return <Initializing />;
};
