import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  fetchUserProfile,
  selectIntroUpdatingState,
  selectIsUserProfileDataLoaded,
  selectIsIntroCompleted,
  updateIntroStatus,
  selectIsOrgChartDataLoaded,
  fetchOrgChart,
} from '../../redux/user';
import { Initializing } from './Initializing';

export const FiguringOutHome = () => {
  const dispatch = useAppDispatch();
  const introUpdatingState = useAppSelector(selectIntroUpdatingState);
  const isIntroCompleted = useAppSelector(selectIsIntroCompleted);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isUserProfileDataLoaded = useAppSelector(selectIsUserProfileDataLoaded);
  const isOrgChartDataLoaded = useAppSelector(selectIsOrgChartDataLoaded);

  useEffect(() => {
    introUpdatingState === 'never' && dispatch(updateIntroStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introUpdatingState]);

  useEffect(() => { 
    isUserProfileDataLoaded || dispatch(fetchUserProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserProfileDataLoaded]);

  useEffect(() => {
      isOrgChartDataLoaded || dispatch(fetchOrgChart());
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrgChartDataLoaded]);

  useEffect(() => {
    pathname !== '/welcome' &&
      introUpdatingState === 'done' &&
      !isIntroCompleted &&
      navigate('/welcome', { replace: true });
  }, [introUpdatingState, isIntroCompleted, navigate, pathname]);

  return <Initializing />;
};
