import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { GenericErrorDialog } from '../components/dialogs/GenericErrorDialog';
import { BannerContainer } from '../components/navigation';
import Snackbar from '../components/notification/Snackbar';
import { FinalConfirmationModal } from '../components/onboarding-tasks/FinalConfirmationModal';
import { TaskSection } from '../components/onboarding-tasks/TaskSection';
import { PanelWithHeader } from '../components/PanelWithHeader';
import { useAppDispatch, useAppSelector } from '../redux';
import {
  openCongratulations,
  propagateError,
  selectCheckCompleteLoadingState,
  selectConfirmLoadingState,
  selectCongratulationsOpen,
  selectDayOneSections,
  selectFutureSections,
  selectNeedsCofirmation,
  selectRefreshLoadingState,
  selectSendTaskLoadingState,
  selectTaskCounts,
  selectTasksLoadingState,
  selectWeekOneSections,
} from '../redux/task';

export const OnboardingTasks = () => {
  const dispatch = useAppDispatch();

  const needsConfirmation = useAppSelector(selectNeedsCofirmation);
  const congratulationsOpen = useAppSelector(selectCongratulationsOpen);

  const checkCompleteLoadingState = useAppSelector(selectCheckCompleteLoadingState);
  const tasksLoadingState = useAppSelector(selectTasksLoadingState);
  const refreshLoadingState = useAppSelector(selectRefreshLoadingState);
  const sendTaskLoadingState = useAppSelector(selectSendTaskLoadingState);
  const confirmLoadingState = useAppSelector(selectConfirmLoadingState);

  const dayOneSections = useAppSelector(selectDayOneSections);
  const weekOneSections = useAppSelector(selectWeekOneSections);
  const futureSections = useAppSelector(selectFutureSections);

  const taskCounts = useAppSelector(selectTaskCounts);

  const [openFinalConfirmation, setOpenFinalConfirmation] = useState(false);
  const [finalToSubmit, setFinalToSubmit] = useState<{ sectionId: string; taskId?: string } | null>(
    null
  );

  return (
    <>
      <Snackbar
        message={"Congratulations! You're all set."}
        isOpen={congratulationsOpen}
        success={true}
        onClose={() => dispatch(openCongratulations(false))}
      />

      <BannerContainer
        {...{
          sx: needsConfirmation ? { fontSize: 12, color: 'rgba(0, 0, 0, 0.72)', py: '16px' } : null,
        }}>
        {needsConfirmation ? (
          <>
            It looks like you have completed all Day 1 and Week 1 tasks. Please confirm:
            <Button
              variant="contained"
              sx={{ p: '12px 86px', ml: '8px', bgcolor: 'rgba(0, 137, 199, 1)' }}
              onClick={() => setOpenFinalConfirmation(true)}>
              Confirm
            </Button>
          </>
        ) : (
          'Welcome to Allied Universal! Please use this list as a guide for your onboarding process. These onboarding tasks are designed to help you get up to speed quickly and efficiently.'
        )}
      </BannerContainer>

      {tasksLoadingState.dataFetching || checkCompleteLoadingState.dataFetching ? (
        <CircularProgress sx={{ position: 'fixed', top: '50%', left: '50%' }} />
      ) : tasksLoadingState.dataErrored || checkCompleteLoadingState.dataErrored ? (
        <Grid container position="fixed" top="50%" justifyContent="center" alignItems="center">
          <ErrorOutlineRoundedIcon sx={{ fontSize: '48px', color: '#F96767', mr: '16px' }} />
          <Grid>
            <Typography fontWeight={600} fontSize="20px">
              Failed to Load
            </Typography>
            <Typography>
              The engineers have been notified.
              <br />
              Please try again later.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <GenericErrorDialog
            open={
              refreshLoadingState.dataErrored ||
              tasksLoadingState.dataErrored ||
              sendTaskLoadingState.dataErrored ||
              confirmLoadingState.dataErrored
            }
            onClose={() => {
              refreshLoadingState.dataErrored &&
                dispatch(propagateError({ key: 'refreshLoadingState', errorState: false }));
              tasksLoadingState.dataErrored &&
                dispatch(propagateError({ key: 'tasksLoadingState', errorState: false }));
              sendTaskLoadingState.dataErrored &&
                dispatch(propagateError({ key: 'sendTaskLoadingState', errorState: false }));
              confirmLoadingState.dataErrored &&
                dispatch(propagateError({ key: 'confirmLoadingState', errorState: false }));
            }}
          />
          {(refreshLoadingState.dataFetching || confirmLoadingState.dataFetching) && (
            <Grid
              container
              sx={{
                position: 'fixed',
                zIndex: 100,
                justifyContent: 'center',
                top: '112px',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                bgcolor: 'rgb(0, 0, 0, .3)',
              }}>
              <CircularProgress sx={{ color: '#FFFFFF', fontSize: '64px' }} />
            </Grid>
          )}

          <FinalConfirmationModal
            open={openFinalConfirmation}
            setOpenFinalConfirmation={setOpenFinalConfirmation}
            setFinalToSubmit={setFinalToSubmit}
            finalToSubmit={finalToSubmit}
          />
          <Grid container p="38px 48px" gap={5} justifyContent="flex-start">
            {dayOneSections.length && (
              <PanelWithHeader
                header="Day 1"
                subHeader="Tasks"
                sx={{ maxWidth: '648px' }}
                tasksCompleted={taskCounts.completedDayOne}
                totalTasks={taskCounts.totalDayOne}>
                {dayOneSections.map((sectionId) => (
                  <TaskSection
                    sectionId={sectionId}
                    setOpenFinalConfirmation={setOpenFinalConfirmation}
                    setFinalToSubmit={setFinalToSubmit}
                    key={sectionId}
                  />
                ))}
              </PanelWithHeader>
            )}

            <Grid container direction="column" maxWidth="max-content" gap={5}>
              {weekOneSections.length && (
                <PanelWithHeader
                  header="Week 1"
                  subHeader="Tasks"
                  sx={{ width: '648px' }}
                  tasksCompleted={taskCounts.completedWeekOne}
                  totalTasks={taskCounts.totalWeekOne}>
                  {weekOneSections.map((sectionId) => (
                    <TaskSection
                      sectionId={sectionId}
                      setOpenFinalConfirmation={setOpenFinalConfirmation}
                      setFinalToSubmit={setFinalToSubmit}
                      key={sectionId}
                    />
                  ))}
                </PanelWithHeader>
              )}

              {futureSections.length && (
                <PanelWithHeader
                  header="Future"
                  subHeader="Tasks"
                  sx={{ width: '648px' }}
                  tasksCompleted={taskCounts.completedFuture}
                  totalTasks={taskCounts.totalFuture}>
                  {futureSections.map((sectionId) => (
                    <TaskSection
                      onFuture={true}
                      sectionId={sectionId}
                      setOpenFinalConfirmation={setOpenFinalConfirmation}
                      setFinalToSubmit={setFinalToSubmit}
                      key={sectionId}
                    />
                  ))}
                </PanelWithHeader>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
