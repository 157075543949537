import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { CheckLoginStatus } from './components/routes/CheckLoginStatus';
import { Fallback } from './components/routes/Fallback';
import { LandingPageTemplate } from './components/routes/LandingPageTemplate';
import { PageContainerTemplate } from './components/routes/PageContainerTemplate';
import { PageTemplate } from './components/routes/PageTemplate';
import { Dashboard } from './pages/Dashboard';
import { GetConnected } from './pages/GetConnected';
import { OnboardingTasks } from './pages/OnboardingTasks';
import { ResourcesAndTools } from './pages/ResourcesAndTools';
import { Welcome } from './pages/Welcome';
import { WhoWeAre } from './pages/WhoWeAre';
import { globalStyles, theme } from './themes';

function App() {
  return (
    <>
      <CssBaseline enableColorScheme />
      <GlobalStyles {...{ styles: globalStyles }} />

      <ThemeProvider {...{ theme }}>
        <Routes>
          <Route {...{ element: <CheckLoginStatus /> }}>
            <Route {...{ element: <PageContainerTemplate /> }}>
              <Route {...{ element: <LandingPageTemplate /> }}>
                <Route {...{ path: '/', element: <Dashboard /> }} />
                <Route {...{ path: '/welcome', element: <Welcome /> }} />
              </Route>

              <Route
                {...{
                  element: <PageTemplate />,
                }}>
                <Route {...{ path: '/who-we-are', element: <WhoWeAre /> }} />
                <Route {...{ path: '/tasks', element: <OnboardingTasks /> }} />
                <Route {...{ path: '/get-connected', element: <GetConnected /> }} />
                <Route {...{ path: '/resources-tools', element: <ResourcesAndTools /> }} />
              </Route>
            </Route>
          </Route>

          <Route {...{ path: '*', element: <Fallback /> }} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
