import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useResetScroll = () => {
  const mainContainerRef = useRef<HTMLElement>();
  const { pathname } = useLocation();

  useEffect(() => {
    mainContainerRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return mainContainerRef;
};
