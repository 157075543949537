import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingState, LoadingStateKey, UserProfile, UserState } from './user.types';

export const defaultLoadingState: LoadingState = { isDataLoaded: false, isDataFetching: false };

export const initialState: UserState = {
  userProfileLoadingState: { ...defaultLoadingState },
  userProfile: null,
  isIntroCompleted: { updating: 'never' },
  orgChartDataLoadingState: { ...defaultLoadingState },
  orgChart: {}
};

const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    startUpdatingIntroCompleted: (state) => {
      state.isIntroCompleted = { updating: 'ongoing' };
    },

    updateIntroCompleted: (state, { payload: isIntroCompleted }: PayloadAction<boolean>) => {
      state.isIntroCompleted = { updating: 'done', state: isIntroCompleted };
    },

    setDataFetchingStarted: (state, { payload: loadingStateKey }: { payload: LoadingStateKey }) => {
      state[loadingStateKey].isDataFetching = true;
    },

    setDataFetchingEnded: (state, { payload: loadingStateKey }: { payload: LoadingStateKey }) => {
      state[loadingStateKey].isDataFetching = false;
    },

    updateUserProfile: (state, { payload: userInfo }: { payload: UserProfile }) => {
      state.userProfile = userInfo;
      state.userProfileLoadingState.isDataLoaded = true;
      state.userProfileLoadingState.isDataFetching = false;
    },

    populateOrgChartData: (state, { payload: orgChartList }: { payload: Record<string, UserProfile[]> }) => {
      state.orgChart = orgChartList;
      state.orgChartDataLoadingState.isDataLoaded = true;
      state.orgChartDataLoadingState.isDataFetching = false;
    },
  },
});

export const {
  reducer: userReducer,
  actions: { 
    startUpdatingIntroCompleted, 
    updateIntroCompleted,
    setDataFetchingStarted,
    setDataFetchingEnded,
    updateUserProfile,
    populateOrgChartData
  },
} = userSlice;
