import { useAppSelector } from '../../redux';
import { selectTaskCounts } from '../../redux/task';

export const routesMap = {
  '/who-we-are': {
    label: 'Who We Are',
  },

  '/tasks': {
    label: 'Onboarding Tasks',

    useCounter: () => { const { totalDayOne, completedDayOne, totalWeekOne, completedWeekOne } = useAppSelector(selectTaskCounts); return (totalDayOne - completedDayOne) + (totalWeekOne - completedWeekOne) },
  }, 

  '/get-connected': {
    label: 'Get Connected',

    info: 'Here, at Allied Universal, we believe collaboration and teamwork are key to our success. We encourage you to meet and get connected with colleagues as you work together and learn more about the team.<br />Please use this page as a resource to find key contacts within the team.',
  },

  '/resources-tools': {
    label: 'Resources & Tools',

    info: 'Please see this quick reference for key resources and tools to help you get started in your new role.',
  },
};

type RoutesMap = typeof routesMap;

export type RoutePath = keyof RoutesMap;

export type RoutePathWithInfo = RoutePath extends infer R
  ? R extends RoutePath
    ? RoutesMap[R] extends { info: string }
      ? R
      : never
    : never
  : never;

export const isRoutePathWithInfo = (routePath: RoutePath): routePath is RoutePathWithInfo =>
  'info' in routesMap[routePath];

export type RoutePathWithCounter = RoutePath extends infer R
  ? R extends RoutePath
    ? RoutesMap[R] extends { useCounter: () => number }
      ? R
      : never
    : never
  : never;

export const isRoutePathWithCounter = (routePath: RoutePath): routePath is RoutePathWithCounter =>
  'useCounter' in routesMap[routePath];