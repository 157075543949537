import { configureStore, Store } from '@reduxjs/toolkit';
import {
  Provider,
  useDispatch,
  useSelector,
  useStore,
  type TypedUseSelectorHook,
} from 'react-redux';
import { authReducer } from './auth/authSlice';
import { taskReducer } from './task/taskSlice';
import { userReducer } from './user/userSlice';

export const createStore = () =>
  configureStore({
    reducer: {
      task: taskReducer,
      user: userReducer,
      auth: authReducer,
    },
  });

const store = createStore();

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const AppStateProvider = ({ children }: { children: React.ReactNode }) => (
  <Provider {...{ store }}>{children}</Provider>
);

export const useAppSelector = useSelector as TypedUseSelectorHook<AppState>;
export const useAppDispatch = useDispatch as () => AppDispatch;
export const useAppStore = useStore as () => Store<AppState>;
