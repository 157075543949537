import Paper from '@mui/material/Paper';

/**
* App footer
*/

export const AppFooter = () => {
  return (
    <Paper sx={{ 
      position: 'absolute', 
      bottom: 0, 
      left: 0, 
      right: 0, 
      height: 56, 
      bgcolor: '#005F90', 
      borderRadius: 0 
      }}/>
  );
};
