import { Grid, Typography } from '@mui/material';
import missionImg from '../../images/mission_half.jpg';
import missionPh from '../../images/mission_half_ph.png';
import visionImg from '../../images/vision_half.jpg';
import visionPh from '../../images/vision_half_ph.png';
import { ImageWithPlaceholder } from '../common/ImageWithPlaceholder';

const HalfImagePanel = ({
  img,
  title,
  description,
  placeholder,
  aspectRatio,
}: {
  img: string;
  title: string;
  description: string;
  placeholder: string;
  aspectRatio: number;
}) => (
  <Grid item container {...{ flexWrap: 'nowrap', xs: 6 }}>
    <Grid
      container
      item
      {...{
        xs: 6,

        sx: {
          bgcolor: 'rgb(1, 45, 70)',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          p: '5%',
          color: 'white',
        },
      }}>
      <Typography fontSize="40px" fontWeight="700" fontFamily="Trade Gothic">
        {title}
      </Typography>
      <Typography fontFamily="Trade Gothic">{description}</Typography>
    </Grid>

    <ImageWithPlaceholder
      {...{
        src: img,
        alt: title,
        placeholder,
        aspectRatio,
        sx: { width: '50%', backgroundColor: '#022b40' },
      }}
    />
  </Grid>
);

/**
 * Vision panel for "Who We Are" page
 */
export const Vision = () => (
  <HalfImagePanel
    {...{
      img: visionImg,
      title: 'Vision',
      description: "Be the world's most trusted services partner.",
      placeholder: visionPh,
      aspectRatio: 1.06,
    }}
  />
);

/**
 * Mission panel for "Who We Are" page
 */
export const Mission = () => (
  <HalfImagePanel
    {...{
      img: missionImg,
      title: 'Mission',

      description:
        'Allied Universal®, through its unparalleled customer relationships, provides proactive solutions, cutting-edge smart technology,  and tailored services that allow customers  to focus on their core business.',

      placeholder: missionPh,
      aspectRatio: 1.06,
    }}
  />
);
