import { Box, Grid } from '@mui/material';
import { GetConnectedButton } from '../components/dashboard/GetConnectedButton';
import { ResourcesAndToolsButton } from '../components/dashboard/ResourcesAndToolsButton';
import { WhoWeAreButton } from '../components/dashboard/WhoWeAreButton';
import { InfoBanner } from '../components/notification/InfoBanner';
import { OnboardingPanel } from '../components/OnboardingPanel';
import phenomenalLogo from '../images/logo_phenomenal.svg';
import { useAppSelector } from '../redux';
import { selectUserProfile } from '../redux/user/userSelectors';

/**
 * Dashboard page with buttons for navigation
 */
export const Dashboard = () => {
  const user = useAppSelector(selectUserProfile);

  return (
    <>
      <InfoBanner
        {...{
          title: `Welcome${user?.firstName ? ' ' : ''}${user?.firstName || ''}!`,
          message:
            'Our goal is to ensure you have the support and resources you need for a Phenomenal Start in your new role. Consider this your home base for training, resources, and support. In this portal, you can access a variety of topics from learning about our culture, getting connected to the team, employee benefits, and more.',
          sx: { textAlign: 'left' },
        }}
      />

      <Grid container item gap={2} mt={5} mb={25} flexWrap="nowrap" justifyContent="center">
        <OnboardingPanel />

        <WhoWeAreButton />

        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <GetConnectedButton />
          <ResourcesAndToolsButton />
        </Box>
      </Grid>

      <Box {...{ position: 'sticky', top: '100%' }}>
        <Box {...{ width: '100%', position: 'absolute', bottom: '40px' }}>
          <Box
            {...{
              component: 'img',
              src: phenomenalLogo,
              alt: 'Phenomenal Logo',
              m: 'auto',
              display: 'block',
            }}
          />
        </Box>
      </Box>
    </>
  );
};
