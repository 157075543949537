import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SxProps } from '@mui/material/styles';
import Mail from '../images/mail.svg';
import Profile from '../images/profile.png';
import Vector from '../images/vector.svg';
import IconButton from "@mui/material/IconButton";

/**
* Role Panel component with header and user details
*/

export const RolePanel = ({
    name, 
    role,
    email,
    teamLink,
    header,
    sx
}: {
    /**
    * String to be display the name
    */
    name: string,
    /**
    * String to be display the role
    */
    role: string,
    /**
    * String that contains the user email
    */
    email: string,
    /**
    * String that contains the user teams link
    */
    teamLink: string,
    /**
    * String to be displayed in the header of the component
    */
    header?: string | null,
    /**
    * Custom styling using sx
    */
    sx?: SxProps
}) => {
    return (
        <Grid container
            {...{
                sx: {
                    borderRadius: '4px',
                    boxShadow: '0px 1px 8px rgba(24, 67, 105, 0.2)',
                    bgcolor: '#FFFFFF',
                    flexDirection: 'column',
                    width: '280px',
                    ...sx,
                }
        }}>
            {header && <Box display="flex" alignItems='center' bgcolor='#005F90' p='12px' height='40px' borderRadius='4px 4px 0px 0px'>
                <Typography color='#FFFFFF' fontWeight='600' width="70%">
                    {header}
                </Typography>
            </Box>}

            <Grid container direction='row' alignItems='center' p='12px' wrap="nowrap">
                <Box component='img' src={Profile} alt='profile' mr={1}/>
                <Box display='flex' flexDirection='column' width='75%' gap={0.5}>
                    <Typography color='rgba(0, 0, 0, 0.72)' fontSize='12px' fontWeight='600'>
                        {name}
                    </Typography>
                    <Grid item container direction='row' justifyContent='space-between'>
                        <Box display="flex" justifyContent='space-between' alignItems='end' width='100%'>
                            <Typography color='rgba(0, 0, 0, 0.72)' fontSize='12px' fontWeight='400' width='75%'>
                                {role}
                            </Typography>
                            <Grid container item justifyContent={teamLink ? 'space-between' : 'flex-end'} width='25%'>
                                {teamLink && <IconButton sx={{ padding: '0px'}} href={`https://teams.microsoft.com/l/chat/0/0?users=${teamLink}`} target="_blank">
                                    <img src={Vector} alt='teams'/>
                                </IconButton>}
                                <IconButton sx={{ padding: '0px'}} href={`mailto:${email}`}>
                                    <img src={Mail} alt='mail' width='14.3px'/>
                                </IconButton>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
};