import { Grid } from "@mui/material"
import { BenefitsAndPerks } from "../components/resources-and-tools/BenefitsAndPerks"
import { ExploreOurTools } from "../components/resources-and-tools/ExploreOurTools"
import { FAQ } from "../components/resources-and-tools/FAQ"
import { PayAndExpenses } from "../components/resources-and-tools/PayAndExpenses"

export const ResourcesAndTools = () => {
    return (
        <Grid container gap={4} {...{ flexWrap: 'nowrap', py: '36px', px: '48px' }}>
            <BenefitsAndPerks />

            <PayAndExpenses />

            <Grid container direction="column" gap={3} width="max-content" ml="auto">
                <ExploreOurTools />
                <FAQ />
            </Grid>
        </Grid>
    )
}