import red from "@mui/material/colors/red";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";

interface ISuccessSnackbarOptions {
    message: string,
    success: boolean,
    isOpen: boolean,
    onClose: () => void
}

const SuccessSnackbar = ({message, success, isOpen, onClose}: ISuccessSnackbarOptions) => {

    return ( 
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={onClose}
            message={
                <Grid container alignItems="center" gap={2} p="10px 16px">
                    {
                        success ? 
                        <CheckCircle {...{ sx: { color: 'rgba(102, 214, 63, 1)', fontSize: '44px' } }} />
                        :
                        <Error {...{ sx: { color: red[500], fontSize: '44px' } }} />
                    }
                    <Grid item>{message}</Grid>
                </Grid>
            }/>
    )
}

export default SuccessSnackbar;