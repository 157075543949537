import { createSlice } from "@reduxjs/toolkit";
import { SectionId, TaskId, TaskLoadingState, TaskLoadingStateKey, TaskState } from "./task.types";

type Section = {
    id: string;
    checked: boolean;
    sectionName: string;
    description?: string;
    tooltip?: string;
    refresh?: boolean;
    tasks?: Task[];
}

type Task = {
    id: string
    taskName: string;
    checkable: boolean;
    checked: boolean;
    link?: string;
    refresh?: boolean;
    subtasks?: Exclude<Task, 'subTasks'>[];
}

const defaultLoadingState: TaskLoadingState = { dataFetching: false, dataErrored: false }

export const initialState: TaskState = {
    tasksLoadingState: { ...defaultLoadingState },
    sendTaskLoadingState: { ...defaultLoadingState },
    refreshLoadingState: { ...defaultLoadingState },
    confirmLoadingState: { ...defaultLoadingState },
    checkCompleteLoadingState: { ...defaultLoadingState },

    allCompleted: false,
    congratulationsOpen: false,

    tasksTree: {
        dayOneTasks: [],
        weekOneTasks: [],
        futureTasks: [],
    },
    taskRefMap: {},
    sectionRefMap: {}
}

const taskSlice = createSlice({
    name: 'task',
    initialState,

    reducers: {

        flagFetching: (state, { payload: key }: { payload: TaskLoadingStateKey }) => {
          state[key].dataFetching = true
        },

        propagateError: (state, { payload: {key, errorState} }: { payload: {key: TaskLoadingStateKey, errorState: boolean} }) => {
          state[key].dataErrored = errorState
          state[key].dataFetching = false
        },

        populateCheckCompleted: (state, { payload: complete }: { payload: boolean }) => {
          state.allCompleted = complete
        },

        populateTasks: (state, { payload: data }: { payload: { dayOneTasks: Section[], weekOneTasks: Section[], futureTasks: Section[] } }) => {
          ['dayOneTasks', 'weekOneTasks', 'futureTasks'].forEach((category) => {
            data[category as keyof typeof data].forEach((section) => {
              state.tasksTree[category as keyof typeof state.tasksTree].push(section.id)

              const { checked, tasks, ...rest } = section
              state.sectionRefMap[section.id] = {...rest, tasks: tasks && tasks.map((task) => task.id), state: { local: checked, remote: checked }}

              if (section['tasks']) {
                section['tasks'].forEach((task) => {
                    const {checked, subtasks, ...rest} = task

                    state.taskRefMap[task.id] = {...rest, subtasks: subtasks && subtasks.map((subtask) => subtask.id), state: { local: checked, remote: checked }}

                    if (task.subtasks) {
                        task.subtasks.forEach((subtask) => {
                            const {checked, subtasks, ...rest} = subtask

                            state.taskRefMap[subtask.id] = {...rest, state: { local: checked, remote: checked }}
                        })
                    }

                })
              }
            }) 
          })
        },

        markItem: (state, {payload: { sectionId, taskId }}: { payload: { sectionId: SectionId, taskId?: TaskId } }) => {
            if (taskId) {
              state.taskRefMap[taskId].state.local = !state.taskRefMap[taskId].state.local
            }
  
            if (state.sectionRefMap[sectionId].tasks) {
              state.sectionRefMap[sectionId].state.local = state.sectionRefMap[sectionId].tasks!.every((task) => {
                  if (state.taskRefMap[task].subtasks) {
                    return state.taskRefMap[task].state.local && state.taskRefMap[task].subtasks!.every((subtask) => state.taskRefMap[subtask].state.local)
                  }
  
                  return state.taskRefMap[task].state.local
              })
            } else {
              state.sectionRefMap[sectionId].state.local = !state.sectionRefMap[sectionId].state.local
            }
        },

        updateRemoteState: (state, { payload: { data } }: { payload: { data: {id: string, checked: boolean;}[] } }) => {
            data.forEach(({ id, checked }) => {
              if (state.sectionRefMap[id]) {
                state.sectionRefMap[id].state.remote = checked
              } else if (state.taskRefMap[id]) {
                state.taskRefMap[id].state.remote = checked
              }
            })
        },

        updateLocalToMatchRemote: (state) => {
        Object.values(state.taskRefMap).forEach((item) => {
            item.state.local = item.state.remote
        })

        Object.values(state.sectionRefMap).forEach((item) => {
            item.state.local = item.state.remote
        })
        },

        populateRefresh: (state, { payload: { data } }: { payload: { data: { id: string; checked: boolean }[] } }) => {
            data.forEach(({id, checked}) => {
              if (state.sectionRefMap[id]) {
                state.sectionRefMap[id].state.local = checked
                state.sectionRefMap[id].state.remote = checked
              } else if (state.taskRefMap[id]) {
                state.taskRefMap[id].state.local = checked
                state.taskRefMap[id].state.remote = checked
              }
            })
        },

        openCongratulations: (state, { payload: open }: {  payload: boolean }) => {
          state.congratulationsOpen = open
        },

        disableAllBoxes: (state) => {
          Object.values(state.taskRefMap).forEach((item) => item.checkable = false)
        }
    }
})

export const {
    actions: { 
      flagFetching,
      propagateError,
      populateCheckCompleted,
      populateTasks, 
      markItem, 
      updateRemoteState, 
      updateLocalToMatchRemote, 
      populateRefresh, 
      openCongratulations,
      disableAllBoxes,
    },
    reducer: taskReducer
} = taskSlice