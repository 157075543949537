import { CircularProgress, Grid } from '@mui/material';
import { AppHeader } from '../navigation';

export const Initializing = () => (
  <>
    <AppHeader />

    <Grid
      container
      {...{
        position: 'fixed',
        top: 72,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress />
    </Grid>
  </>
);
