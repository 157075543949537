import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { HalfButton } from '../buttons/GenericButtons';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { useAppDispatch } from '../../redux';
import { confirmComplete, sendCheckTask } from '../../redux/task/taskDispatches';

/**
 * Dialog for confirming completion of all onboarding tasks
 */
export const FinalConfirmationModal = ({
    open,
    setOpenFinalConfirmation,
    setFinalToSubmit,
    finalToSubmit,
}: {
    /**
     * Boolean to display dialog or not
     */
    open: boolean;
    /**
     * Set state for open or not
     */
    setOpenFinalConfirmation: React.Dispatch<React.SetStateAction<boolean>>; 
    /**
     * Set state for the final item to be submitted
     */
    setFinalToSubmit: React.Dispatch<React.SetStateAction<{ sectionId: string; taskId?: string | undefined; } | null>>; 
    /**
     * Final itemto be submitted
     */
    finalToSubmit: { sectionId: string; taskId?: string; } | null
}) => {
  const dispatch = useAppDispatch()

  return (
    <Dialog disablePortal {...{ open }}>
      <DialogTitle sx={{ textAlign: 'center', pt: '36px', pb: '24px' }}>
        <HelpOutlineRoundedIcon color="primary" sx={{ fontSize: '64px' }}/>
        <Typography
          {...{
            fontSize: '24px',
            fontWeight: 500,
          }}>
            Please Confirm
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ padding: '48px 48px 40px 48px' }}>
            <Typography mb="20px">
            You are about to check off all Day 1 and Week 1 tasks which will update your compliance status.
            </Typography>

            <Typography>
            Once confirmed you will not be able to make any further changes. Are you sure you have completed all Day 1 and Week 1 tasks?
            </Typography>        
      </DialogContent>

      <DialogActions
        {...{
          sx: (theme) => ({ p: '32px 48px', gap: 2, [theme.breakpoints.down('md')]: { px: '26px' } }),
        }}>
            <HalfButton {...{ variant: 'outlined', sx: { border: '2px solid rgba(0, 0, 0, 0.48)', color: 'rgba(0, 0, 0, 0.48)' } }} onClick={() => {setOpenFinalConfirmation(false); setFinalToSubmit(null);}}>Cancel</HalfButton>
            <HalfButton 
              {...{ bgcolor: 'rgba(63, 108, 150, 1)' }} 
              onClick={
                () => {
                  finalToSubmit ? dispatch(sendCheckTask( finalToSubmit.sectionId, finalToSubmit.taskId, true )) : dispatch(confirmComplete())
                  setOpenFinalConfirmation(false)
                }
              }
              >Confirm</HalfButton>
      </DialogActions>
    </Dialog>
  );
};
