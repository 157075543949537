import { Button, Typography } from '@mui/material';
import { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { InfoBanner } from '../components/notification/InfoBanner';
import { LOCAL_STORAGE_VIDEO_STATE } from '../config/localStorageKeys';
import thumbnail from '../images/steve_jones.jpg';
import introVideo from '../media/steve_jones_introduction_(1080p).mp4';
import { useAppDispatch, useAppSelector } from '../redux';
import { selectIsIntroCompleted, selectUserProfile, sendIntroCompleted } from '../redux/user';

/**
 * Welcome page
 */
export const Welcome = () => {
  const user = useAppSelector(selectUserProfile);
  const isIntroCompleted = useAppSelector(selectIsIntroCompleted);
  const dispatch = useAppDispatch();
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const isTimeUpdated = useRef(false);

  const currentWatched = useRef<number>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_VIDEO_STATE) ?? '{ "currentWatched": 0 }')
      .currentWatched
  );

  const furthestWatched = useRef(currentWatched.current);

  const rememberCurrentWatched = () => {
    currentWatched.current &&
      localStorage.setItem(
        LOCAL_STORAGE_VIDEO_STATE,
        JSON.stringify({ currentWatched: currentWatched.current })
      );
  };

  return (
    <>
      <InfoBanner
        {...{
          title: `Welcome${user?.firstName ? ' ' : ''}${user?.firstName || ''}!`,

          message:
            'We are excited to have you join the team. Here is a welcome message from our CEO, Steve Jones.',
        }}
      />

      <ReactPlayer
        controls
        playing
        {...{
          ref: videoPlayerRef,
          url: introVideo,
          light: thumbnail,
          width: 840,
          height: 472.5,
          style: { margin: '56px auto 0', overflow: 'hidden', borderRadius: '2px' },
          config: { file: { attributes: { controlsList: 'noplaybackrate nodownload' } } },

          onEnded: () => {
            localStorage.removeItem(LOCAL_STORAGE_VIDEO_STATE);
            dispatch(sendIntroCompleted());
          },

          onProgress: ({ playedSeconds, played }) => {
            const delta = playedSeconds - currentWatched.current;

            if (delta < 0 || delta > 2) {
              return;
            }

            currentWatched.current = played === 1 ? 0 : playedSeconds;
            furthestWatched.current = Math.max(furthestWatched.current, playedSeconds);
            rememberCurrentWatched();
          },

          onReady: () => {
            if (videoPlayerRef.current && !isTimeUpdated.current && currentWatched.current) {
              videoPlayerRef.current.seekTo(currentWatched.current);
            }

            isTimeUpdated.current = true;
          },

          onSeek: (seconds) => {
            if (!isIntroCompleted && seconds > furthestWatched.current) {
              videoPlayerRef.current?.seekTo(furthestWatched.current);
            } else {
              currentWatched.current = seconds;
              rememberCurrentWatched();
            }
          },
        }}
      />

      <Button
        {...{
          component: Link,
          to: '/',
          disabled: !isIntroCompleted,

          sx: {
            display: 'flex',
            width: 248,
            height: 44,
            mx: 'auto',
            my: '32px',
            backgroundColor: '#005F90',
            '&:hover': { backgroundColor: '#065881' },
            '&[class*="disabled"]': { backgroundColor: '#C2C2C2' },
          },
        }}>
        <Typography
          {...{
            color: '#fff',
            fontSize: '16px',
            fontWeight: 600,
            textAlign: 'center',
          }}>
          Get Started
        </Typography>
      </Button>
    </>
  );
};
