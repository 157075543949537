import { GeneralDirectory } from "../components/get-connected/GeneralDirectory";
import { AffiliatedAssociations } from "../components/get-connected/AffiliatedAssociations";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { OrgChart } from "../components/get-connected/OrgChart";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "../redux";
import { selectIsOrgChartDataLoaded } from "../redux/user";

/**
* Get Connected page
*/
export const GetConnected = () => {
    const isOrgChartDataLoaded = useAppSelector(selectIsOrgChartDataLoaded);
    
    return (
        <Grid container justifyContent="space-between" py={4} px={6}>
            <Box width="50%">
                <GeneralDirectory/>
                <AffiliatedAssociations/>
            </Box>

            <Box width="50%">
                {isOrgChartDataLoaded 
                ?   <OrgChart/>
                :   <Grid container height="100%" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Grid>
                }
            </Box>
        </Grid>
    )
};