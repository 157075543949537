import whoWeAreImg from '../../images/who_we_are.jpg';
import whoWeArePh from '../../images/who_we_are_ph.png';
import { DashboardButton } from './DashboardButton';

export const WhoWeAreButton = () => (
  <DashboardButton
    {...{
      link: '/who-we-are',
      label: 'Who We Are',
      image: whoWeAreImg,
      placeholder: whoWeArePh,
      aspectRatio: 1.49,
    }}
  />
);
