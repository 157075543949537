import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { isRoutePathWithCounter, routesMap, type RoutePath } from '../../assets/routes/routesMap';

/**
 * Navigation tab list
 */
export const TabList = () => (
  <Grid
    container
    {...{
      flexWrap: 'nowrap',
      height: 40,
      gap: '24px',
      sx: { backgroundColor: '#0089C7', px: '44px', position: 'absolute', top: 72 },
    }}>
    {(Object.keys(routesMap) as RoutePath[]).map((routePath) => (
      <Tab {...{ routePath, key: routePath }} />
    ))}
  </Grid>
);

const Tab = ({ routePath }: { routePath: RoutePath }) => {
  const { pathname } = useLocation();
  const isActive = routePath === pathname;
  const { label } = routesMap[routePath];
  const isThereCounter = isRoutePathWithCounter(routePath);

  return (
    <Button
      {...{
        component: Link,
        disabled: isActive,
        to: routePath,
        sx: { display: 'block', p: 0, mt: 'auto' },
      }}>
      <TabText {...{ sx: { m: '0 4px 6px' } }}>
        {label}
        {isThereCounter && <Counter {...{ useCounter: routesMap[routePath].useCounter }} />}
      </TabText>

      <Box
        {...{
          height: 4,
          width: '100%',
          sx: { backgroundColor: '#fff', visibility: isActive ? 'unset' : 'hidden' },
        }}
      />
    </Button>
  );
};

const TabText = styled(Typography)({
  color: '#fff',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '20px',
  textAlign: 'center',
  display: 'inline-block',
  height: 20,
  whiteSpace: 'nowrap',
});

const Counter = ({ useCounter }: { useCounter: () => number }) => {
  const count = useCounter();

  return count === 0 ? null : (
    <TabText
      {...{
        component: 'span',
        width: 20,
        sx: { backgroundColor: '#ED3136', borderRadius: '50%', ml: '6px' },
      }}>
      {count}
    </TabText>
  );
};
