import { Box, Typography } from '@mui/material';
import { ReactComponent as ErrorPageSVG } from '../../images/errorPage.svg';

export const ErrorFallback = () => (
  <Box {...{ height: '70vh', pt: '30vh', px: '43px', sx: { textAlign: 'center' } }}>
    <ErrorPageSVG {...{ fill: '#3F6C96', width: 64, height: 64 }} />

    <Typography
      {...{
        color: '#3F6C96',
        fontSize: '20px',
        lineHeight: '32px',
        fontWeight: 500,
        mt: '24px',
      }}>
      Oops... Something went wrong.
    </Typography>

    <Typography
      {...{
        color: 'rgba(0, 0, 0, 0.72)',
        mt: '12px',
        sx: (theme) => ({ [theme.breakpoints.down(450)]: { textAlign: 'left' } }),
      }}>
      Please refresh the page or try again later.
      <br />
      If this issue persists please contact the AUS HelpDesk at (484) 351-1568.
    </Typography>
  </Box>
);
