import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { type SxProps } from '@mui/material/styles';

/**
* Info banner component that takes in a title, message and custom message css
*/

export const InfoBanner = ({
  title,
  message,
  sx
} : {
  /**
  * String to be displayed as the title of the component
  */
  title: string;
  /**
  * String to be displayed as the subtitle of the component
  */
  message: string;
  /**
  * Custom styling using sx
  */
  sx?: SxProps;
}) => {
  return (
    <Box py={3} px={21}
      {...{
        sx: {
          backgroundColor: '#fff',
          borderRadius: '0px',
          borderBottom: '2px solid #B8D2E0',
          boxShadow: '0px 1px 4px 0px #1843691F',
        }
      }}>
        <Typography align='center' color='#005F90' fontSize='24px' fontWeight={600} mt={1}>{title}</Typography>
        <Typography align='center' color='#005F90' fontSize='16px' {...{ sx: { ...sx } }}>{message}</Typography>
    </Box>
  );
};
