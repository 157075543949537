import { createSelector } from '@reduxjs/toolkit';
import type { AppState } from '../store';

const selectUserState = createSelector(
  ({ user: userState }: AppState) => userState,
  (userState) => userState
);

export const selectUserProfile = createSelector(selectUserState, ({ userProfile }) => userProfile);

export const selectIntroUpdatingState = createSelector(
  selectUserState,
  ({ isIntroCompleted }) => isIntroCompleted.updating
);

export const selectIsIntroCompleted = createSelector(
  selectUserState,
  ({ isIntroCompleted }) => isIntroCompleted.updating === 'done' && isIntroCompleted.state
);

export const selectIsUserProfileDataLoaded = createSelector(
  selectUserState,
  ({ userProfileLoadingState }) => userProfileLoadingState.isDataLoaded
);

export const selectIsOrgChartDataLoaded = createSelector(
  selectUserState,
  ({ orgChartDataLoadingState }) => orgChartDataLoadingState.isDataLoaded
);

export const selectDirectorsList = createSelector(
  selectUserState, 
  ({ orgChart }) => {
    if (Object.keys(orgChart).length > 0) {
      return Object.values(
        Object.fromEntries(
          Object.entries(orgChart).filter(([title, ]) => title !== 'direct_reports')
      )).flat().sort();
    }
    return [];
});

export const selectTeamList = createSelector(
  selectUserState,
  ({ orgChart, userProfile }) => {
    if (Object.keys(orgChart).length > 0 && orgChart['direct_reports'].length > 0) {
      const index = orgChart['direct_reports'].findIndex(({userPrincipalName}) => userPrincipalName === userProfile?.userPrincipalName);
      const currentUser = orgChart['direct_reports'][index];
      return (index !== -1 ? [currentUser] : []).concat(orgChart['direct_reports']
      .filter((_, i) => i !== index).sort((a, b) => a.firstName.localeCompare(b.firstName)));
    }
    return [];
  }
);
