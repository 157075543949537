import { Grid } from "@mui/material"
import { SxProps } from '@mui/material/styles';

/**
 * Row Item to be displayed in Panel With Header on Resources and Tools page
 */
export const RowItem = ({
    /**
     * Custom styling for component
     */
    sx,
    /**
     * Content within row
     */
    children
}: {
    sx?: SxProps, 
    children: React.ReactNode
}) => {
    return <Grid container sx={{ padding: '12px', borderBottom: '1px solid #D1DCE6', '&:last-child': { borderBottom: 'unset' }, ...sx }}>
        {children}
    </Grid>
}