import type { Interpolation } from '@emotion/react';
import type { Theme } from '@mui/material';
import TradeGothic from '../assets/fonts/TradeGothic.otf'
import TradeGothicBold from '../assets/fonts/TradeGothicBold.otf'

export const globalStyles: Interpolation<Theme> = [
  {
    body: {
      backgroundColor: '#E9F0F5',
    },
  },

  {
    '@font-face': {
      fontFamily: 'Trade Gothic',
      src: `local("TradeGothic"), url(${TradeGothic}) format("opentype")`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },

  {
    '@font-face': {
      fontFamily: 'Trade Gothic Bold',
      src: `local("TradeGothicBold"), url(${TradeGothicBold}) format("opentype")`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
];