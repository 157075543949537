import { SxProps, Typography } from '@mui/material';

/**
 * Info banner showing the information of the page in the app
 */
export const PageInfoBanner = ({
  info,
}: {
  /**
   * Info string displaying
   */
  info: string;
}) => (
  <BannerContainer>
    {info
      .split('<br />')
      .map((line, i, { length }) => (i < length - 1 ? [line, <br {...{ key: i }} />] : line))}
  </BannerContainer>
);

export const BannerContainer = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => (
  <Typography
    {...{
      color: '#3F6C96',
      fontSize: '14px',
      fontWeight: 600,

      sx: {
        px: '48px',
        py: '28px',
        backgroundColor: '#fff',
        boxShadow: `0px 1px 4px rgba(24, 67, 105, 0.12)`,
        ...sx,
      },
    }}>
    {children}
  </Typography>
);
