import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { isRoutePathWithInfo, routesMap, type RoutePath } from '../../assets/routes/routesMap';
import { AppHeader, PageInfoBanner, TabList } from '../navigation';
import { useResetScroll } from './useResetScroll';

export const PageTemplate = () => {
  const { pathname } = useLocation() as { pathname: RoutePath };
  const isThereBanner = isRoutePathWithInfo(pathname);
  const mainContainerRef = useResetScroll();

  return (
    <>
      <AppHeader />
      <TabList />

      <Box
        {...{
          ref: mainContainerRef,
          width: '100%',
          sx: { position: 'absolute', top: 112, bottom: 0, overflow: 'auto' },
        }}>
        {isThereBanner && <PageInfoBanner {...{ info: routesMap[pathname].info }} />}
        <Outlet />
      </Box>
    </>
  );
};
