import { createSelector } from '@reduxjs/toolkit';
import type { AppState } from '../store';

const selectAuthState = createSelector(
  ({ auth: authState }: AppState) => authState,
  (authState) => authState
);

export const selectIsLoggedIn = createSelector(selectAuthState, ({ isLoggedIn }) => isLoggedIn);

export const selectIsObtainingAccessTokenInProgress = createSelector(
  selectAuthState,
  ({ isObtainingAccessTokenInProgress }) => isObtainingAccessTokenInProgress
);

export const selectPathnameToSetAfterLogin = createSelector(
  selectAuthState,
  ({ rememberPathnameToReturnAfterLogin: rememberPathnameToSetAfterLogin }) => rememberPathnameToSetAfterLogin
);

export const selectDidErrorOccur = createSelector(
  selectAuthState,
  ({ didErrorOccur }) => didErrorOccur
);
