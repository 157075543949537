import { createTheme } from '@mui/material/styles';

const primaryColor = '#005F90';

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: "#4D5C64" 
    }
  },
  
  typography: {
    button: {
      textTransform: 'none',
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: '#F8F8F8',
          backgroundColor: primaryColor,
        },
      },
    },
    
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(235,243,247)',
          color: '#000000',
          fontSize: '20px',
          backdropFilter: 'blur(36px)',
          borderRadius: 4,
        },
      },
    },
  },
});