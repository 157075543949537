import { PanelWithHeader } from "../PanelWithHeader";
import { Box } from "@mui/material";
import { LinkComponent } from "../resources-and-tools/LinkComponent";

const affiliatedAssociations = [
    {
        label: 'ASIS International',
        url: 'https://www.asisonline.org/'
    },
    {
        label: 'National Association of Security Companies (NASCO)',
        url: 'https://www.nasco.org/'
    },
    {
        label: 'Law Enforcement and Private Security (LEAPS)',
        url: 'https://leapsla.org/'
    },
    {
        label: 'Security Industry Association (SIA)',
        url: 'https://www.securityindustry.org/'
    },
    {
        label: 'International Foundation for Cultural Property Protection (IFCPP)',
        url: 'https://ifcpp.org/'
    }
];

/**
* Affiliated Associations panel for "Get Connected" page
*/
export const AffiliatedAssociations = () => (
    <PanelWithHeader header='Learn about affiliated associations' sx={{ maxWidth: '385px' }}>
        {affiliatedAssociations.map((association, i) => (
            <Box key={i} p="16px" borderBottom='1px solid rgba(209, 220, 230, 1)' sx={{ '&:last-child': { borderBottom: 'unset' } }}>
                <LinkComponent key={i} 
                    href={association.url}
                    sx={{ color: 'rgba(0, 95, 144, 1)', fontSize: '12px', fontWeight: 600, lineHeight: '24px' }}>
                    {association.label}
                </LinkComponent>
            </Box>
            
        ))}
    </PanelWithHeader>
);