import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Avatar, Box, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from '../redux';
import { selectCheckCompleteLoadingState, selectNeedsCofirmation, selectTaskCounts, selectTasksLoadingState } from '../redux/task';
import { PanelWithHeader } from './PanelWithHeader';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';


/**
* Panel for displaying the counts for remaining Onboarding Tasks
*/
export const OnboardingPanel = () => {
    const tasksLoadingState = useAppSelector(selectTasksLoadingState)
    const checkCompleteLoadingState = useAppSelector(selectCheckCompleteLoadingState)

    const { totalDayOne, totalWeekOne, completedDayOne, completedWeekOne } = useAppSelector(selectTaskCounts)
    const totalTasks = totalDayOne + totalWeekOne
    const dayOneCount = totalDayOne - completedDayOne
    const weekOneCount = totalWeekOne - completedWeekOne

    const needsConfirmation = useAppSelector(selectNeedsCofirmation)

    return <PanelWithHeader header="Onboarding Tasks" sx={{ maxWidth: '356px', height: '288px', flexWrap: 'nowrap' }} totalTasks={totalTasks} tasksCompleted={(totalDayOne + totalWeekOne) - (dayOneCount + weekOneCount)}>
        { 
        tasksLoadingState.dataFetching || checkCompleteLoadingState.dataFetching ?
            <Grid container flexGrow={1} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        : tasksLoadingState.dataErrored || checkCompleteLoadingState.dataErrored ?
            <Grid container flexGrow={1} direction="column" justifyContent="center" alignItems="center">
                <ErrorOutlineRoundedIcon color="error" sx={{ fontSize: '48px', mb: '6px' }} />
                <Typography fontWeight="500" fontSize="20px" mb="6px">Failed to Load</Typography>
                <Typography textAlign="center">The engineers have been notified.<Typography component="span" display="block">Please try again later.</Typography></Typography>
            </Grid> 
        :
        needsConfirmation ?

            <Grid container flexGrow={1} flexDirection="column" justifyContent="space-between" p="20px 32px 32px 32px">
                <Grid container flexWrap="nowrap">
                    <Avatar color="error" sx={{ height: '20px', width: '20px', fontSize: '12px', mr: '8px', bgcolor: '#ED3136', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24)' }}>1</Avatar>
                    <Typography fontSize="12px" fontWeight="600">
                        It looks like you have completed all Day 1 and Week 1 tasks. Please review your onboarding task list and confirm completion.
                    </Typography>
                </Grid>

                <Button variant="contained" fullWidth component={RouterLink} to={'/tasks'} sx={{ maxHeight: '48px', boxShadow: "0px 0px 8px rgba(63, 108, 150, 0.32)", bgcolor: '#005F90', fontSize: '16px' }}>
                    Continue<KeyboardArrowRightRoundedIcon fontSize="large" sx={{ m: 0, p: 0 }}/>
                </Button>
            </Grid>

        : dayOneCount + weekOneCount <= totalTasks && dayOneCount + weekOneCount > 0
        ?
            <Grid container direction='column' flexGrow={1} justifyContent="space-between">
                <Box>
                    { 
                        [dayOneCount, weekOneCount].map((count, i) => {
                            return count > 0 && <Grid key={i} container borderBottom="1px solid #D1DCE6" p="16px" height="51px">
                                <Avatar color="error" sx={{ height: '20px', width: '20px', fontSize: '12px', mr: '8px', bgcolor: '#ED3136', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24)' }}>
                                    {count}
                                </Avatar>
                                
                                <Typography fontSize="12px" fontWeight="600">
                                    {i === 0 ? 'Day' : 'Week'} 1 Tasks Remaining
                                </Typography>
                            </Grid>
                        })        
                    }
                </Box>
                

                <Button variant="contained" component={RouterLink} to={'/tasks'} sx={{ py: '12px', m: '32px', maxHeight: '48px', boxShadow: "0px 0px 8px rgba(63, 108, 150, 0.32)", bgcolor: '#005F90', fontSize: '16px' }}>
                    {dayOneCount + weekOneCount === totalTasks
                        ? <>Get Started!</> 
                        : <>Continue<KeyboardArrowRightRoundedIcon fontSize="large" sx={{ m: 0, p: 0 }}/></>
                    }
                </Button>
            </Grid>
            :
            <Grid container direction="column" alignItems="center" p="45px 29px">
                <CheckCircleRoundedIcon sx={{ height: '40px', width: '40px', color: '#66D63F', mb: '12px' }}/>

                <Typography fontWeight="500" fontSize="20px" mb="8px">
                    You're all set!
                </Typography>

                <Typography fontSize="16px">
                    <RouterLink to="/tasks" style={{ textDecoration: 'none', fontWeight: 500, color: '#005F90' }}>Click here</RouterLink> to view your completed tasks.
                    Also, be sure to check out all of the other resources that we have for you! 👉
                </Typography>
            </Grid>
        }
    </PanelWithHeader>
}
