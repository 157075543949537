import { Grid, Typography, List, ListItem } from "@mui/material"
import AddRoundedIcon from '@mui/icons-material/AddRounded';
/**
 * Our Journey to Become an Employer of Choice panel for "Who We Are" page
 */
export const OurJourney = () => {
    return <Grid container bgcolor="#4C5964" color="#FFFFFF" p="72px 100px 64px">
    <Grid container mb="48px">
        <Typography fontSize="40px" fontWeight="700">
            Our Journey to Become an Employer of Choice
        </Typography>
        <Typography fontSize="18px">
            As an organization, we utilize our “There For You” culture to become an Employer of Choice - an employer that attracts, develops, and retains the right talent through effective employee engagement. To us, this means creating an organizational culture where our employees know we are there for them. Our Employer of Choice strategy rests on two pillars: I-Care Leadership and Exceptional Employee Experience.
        </Typography>
    </Grid>

    <Grid container width="max-content" alignItems="flex-start" margin="auto">
        <Grid maxWidth="258px">
            <Typography fontSize="24px" fontWeight="500">I-CARE LEADERSHIP</Typography>
            <Typography m="0">A leader who</Typography>
            <List sx={{ listStyleType: 'disc', pl: 4, pt: 0 }}>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Connects Personally,</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Demonstrates Interest in,</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Supports, and</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Provides Guidance, Empathy, and Compassion</Typography>
                </ListItem>
            </List>
        </Grid>

        <AddRoundedIcon sx={{ transform: 'scale(3.5)', color: '#C2CF4F', mx: '48px', alignSelf: 'center' }}/>

        <Grid>
            <Typography fontSize="24px" fontWeight="500">EXCEPTIONAL EMPLOYEE EXPERIENCE</Typography>
            <Typography m="0">An environment that</Typography>
            <List sx={{ listStyleType: 'disc', pl: 4, pt: 0 }}>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Develops Employees at Every Level</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Promotes From Within</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Is Inclusive and Diverse</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'list-item' }}>
                    <Typography>Focuses on Employee Total Rewards</Typography>
                </ListItem>
            </List>
        </Grid>
    </Grid>
</Grid>
}