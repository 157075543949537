import { PanelWithHeader } from "../PanelWithHeader"
import { RowItem } from "./RowItem"
import CircleIcon from '@mui/icons-material/Circle';
import { Typography, Link, Grid, List, ListItem } from "@mui/material"
import { LinkComponent } from "./LinkComponent";

const FAQRowItem = ({children}: {children: React.ReactNode[]}) => {
    return <RowItem sx={{ gap: 1 }}>
            <Grid container alignItems="flex-start" flexWrap='nowrap'>
                <CircleIcon sx={{ width: '12px', height: '18px', color: 'rgba(0, 95, 144, 0.64)', mr: '6px' }}/>
                {children[0]}
            </Grid>

            <Grid container alignItems='flex-start' flexWrap="nowrap">
                <CircleIcon sx={{ width: '12px', height: '18px', color: '#D9D9D9', mr: '6px', }}/>
                {children[1]}
            </Grid>
    </RowItem>
}

/**
 * FAQ panel on Resources and Tools page
 */
export const FAQ = () => {
    return <PanelWithHeader header="FAQ" sx={{ maxWidth: '632px' }}>
        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">What benefits does the company offer, such as health insurance or retirement plans?</Typography>
            <Typography fontSize="12px">
                <LinkComponent href="https://allieduniversalbenefits.com/log-in/">
                    https://allieduniversalbenefits.com/log-in/
                </LinkComponent>
            </Typography>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">Does the company have any discounts or perks?</Typography>
            <Typography fontSize="12px"> 
                <LinkComponent href="https://allieduniversalbenefits.com/discounts-perks/">
                    https://allieduniversalbenefits.com/discounts-perks/
                </LinkComponent>
            </Typography>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">How is performance evaluated, and what opportunities are there for growth and development?</Typography>

            <Typography fontSize="12px">
                Performance evaluation is an essential part of our company's culture, and we strive to ensure that all employees have opportunities for growth and development. 
                They are administered once a year, and mid-year check-ins conducted by your immediate supervisor. 
                For further details reach out to Libi Del Villar our Talent Management Director who leads our Talent Assessments program. <Link underline="none" href="mailto:Libi.DelVillar@aus.com">Libi.DelVillar@aus.com</Link>
            </Typography>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">What do I do if I have a work-related concern?</Typography>

            <Typography fontSize="12px">
                Employees are expected to bring their concerns to their Supervisors. If not resolved, or if you are not comfortable raising the issue with your Supervisor and/or their manager. If your concern remains unresolved or you are uncomfortable speaking with your either of those individuals, you may contact Regional Human Resources. You may also contact Corporate Human Resources or our ethics hotline at (888) 260-5948 or visit <LinkComponent href="https://aus.ethicspoint.com">aus.ethicspoint.com</LinkComponent>. Contacting the ethics hotline may also be done anonymously, should you choose to do so.
            </Typography>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">How do I resolve I.T. or equipment issues?</Typography>

            <Typography fontSize="12px">
                Call the AUS Helpdesk at (484) 351-1568
            </Typography>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">Who should I speak to if I am approached by the media?</Typography>

            <Grid container direction="column">
                <Typography fontSize="12px">
                    Under no circumstances should any individual other than an authorized Allied Universal media representative talk to or give a statement to the press. Please contact:
                </Typography>    

                <List disablePadding sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem disablePadding sx={{ display: 'list-item' }}>
                        <Typography fontSize="12px">
                            Kari Garcia, Director of Public Relations, at <Link underline="none" href="mailto:Kari.Garcia@aus.com">Kari.Garcia@aus.com</Link> or (949) 826-3560.
                        </Typography>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'list-item' }}>
                        <Typography fontSize="12px">
                            Lori Olin, Vice President of Communications, at <Link underline="none" href="mailto:Lori.Olin@aus.com">Lori.Olin@aus.com</Link> or (949) 294-7560.
                        </Typography>
                    </ListItem>
                </List>
            </Grid>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">What is the social media policy here?</Typography>

            <Typography fontSize="12px">
                <LinkComponent href="https://allieduniversalservices.sharepoint.com/:b:/r/sites/MktSale/Brand%20Toolbox/Social%20Media%20Policy%20(English).docx%20(1).pdf?csf=1&web=1">
                    https://allieduniversalservices.sharepoint.com/:b:/r/sites/MktSale/Brand%20Toolbox/ Social%20Media%20Policy%20(English).docx%20(1).pdf?csf=1&web=1
                </LinkComponent>
            </Typography>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">How do I get updates on the company and news?</Typography>

            <Typography fontSize="12px">
                Our Weekly Insiders provides updates on our organization as well as celebrating employee.
            </Typography>
        </FAQRowItem>

        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">Do we have a company store?</Typography>

            <Typography fontSize="12px">
                Yes, you can access our company store by visiting <LinkComponent href="https://allieduniversalservices.sharepoint.com/CWA/Forms/AllItems.aspx?id=%2FCWA%2FAllied%20Universal%27s%20New%20Company%20Store%2Epdf&parent=%2FCWA">https://allieduniversalservices.sharepoint.com CWAForms/AllItems.aspx id=%2FCWA%2FAllied%20Universal%27s%20New%20Company%20Store%2Epdf&parent=%2FCWA</LinkComponent><br/>This link will provide you with directions on how to order and approvals needed.
            </Typography>
        </FAQRowItem>
        
        <FAQRowItem>
            <Typography fontSize="12px" fontWeight="600">Who is LISA?</Typography>

            <Typography fontSize="12px">
                LISA is the LIVE Interactive Support AI, helping managers and Security Professionals daily with things like direct deposit, timekeeping support, claiming of shifts and automated scheduling. She also assists with sending guard card renewal reminders, provides call off support, and assists in payroll issues with Fix My Pay. LISA is there for our team members 24/7 @ <LinkComponent href="http://lisa.aus.com">LISA.AUS.COM</LinkComponent>.
            </Typography>
        </FAQRowItem>

    </PanelWithHeader>
}