import { Button, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ImageWithPlaceholder } from '../common/ImageWithPlaceholder';

/**
 * Container for dashboard buttons displaying image and label of desired destination
 */
export const DashboardButton = ({
  label,
  size = 'md',
  link,
  image,
  placeholder,
  aspectRatio,
}: {
  /**
   * Label to be displayed on bottom bar
   */
  label: string;
  /**
   * Determine small and medium sizes for the component iteself
   */
  size?: 'sm' | 'md';
  /**
   * The URL of where clicking the button will navigate to
   */
  link: string;
} & (
  | {
      /**
       * The image to be displayed in the component
       */
      image: string;
      placeholder: string;
      aspectRatio: number;
    }
  | {
      image?: never;
      placeholder?: never;
      aspectRatio?: never;
    }
)) => (
  <Button
    {...{
      component: RouterLink,
      to: link,

      sx: {
        padding: 0,
        borderRadius: '4px',
        position: 'relative',
        backgroundColor: 'rgba(0, 137, 199)',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': { backgroundColor: 'rgba(0, 137, 199)' },

        ...(size === 'md'
          ? {
              width: '356px',
              height: '288px',
            }
          : {
              width: '261px',
              height: '136px',
            }),
      },
    }}>
    {image != null && (
      <ImageWithPlaceholder
        {...{
          src: image,
          alt: link,
          placeholder,
          aspectRatio,

          sx: {
            opacity: 0.2,
            width: '100%',
            scale: `${Math.max(aspectRatio / (size === 'md' ? 1.24 : 1.92), 1)}`,
          },
        }}
      />
    )}

    <Grid
      container
      {...{
        bgcolor: '#005F90',
        height: 48,
        p: '12px 16px',
        borderRadius: '0px 0px 4px 4px',
        position: 'absolute',
        bottom: '0',
      }}>
      <Typography {...{ color: '#F8F8F8', fontWeight: 700 }}>{label}</Typography>
    </Grid>
  </Button>
);
