import axios from 'axios';
import { propagateError } from '../auth';
import type { AppDispatch, AppState } from '../store';
import { 
  setDataFetchingEnded,
  setDataFetchingStarted, 
  startUpdatingIntroCompleted, 
  updateIntroCompleted, 
  updateUserProfile, 
  populateOrgChartData 
} from './userSlice';
import { IUserProfile } from './user.types';

export const updateIntroStatus = () => async (dispatch: AppDispatch, getState: () => AppState) => {
  const {
    user: { isIntroCompleted },
    auth: { serviceUrl, accessToken, isLoggedIn },
  } = getState();

  if (!isLoggedIn || isIntroCompleted.updating === 'ongoing') {
    return;
  }

  dispatch(startUpdatingIntroCompleted());

  try {
    const {
      data: { checked: isIntroCompleted },
    } = await axios.get<{ checked: boolean }>(`${serviceUrl}/welcome`, {
      headers: { Authorization: accessToken },
    });

    dispatch(updateIntroCompleted(isIntroCompleted));
  } catch {
    dispatch(propagateError());
  }
};

export const sendIntroCompleted = () => async (dispatch: AppDispatch, getState: () => AppState) => {
  const {
    user: { isIntroCompleted },
    auth: { serviceUrl, accessToken, isLoggedIn },
  } = getState();

  if (
    !isLoggedIn ||
    isIntroCompleted.updating === 'ongoing' ||
    (isIntroCompleted.updating === 'done' && isIntroCompleted.state)
  ) {
    return;
  }

  try {
    await axios.put(`${serviceUrl}/welcome`, undefined, {
      headers: { Authorization: accessToken },
    });

    dispatch(updateIntroCompleted(true));
  } catch {
    dispatch(propagateError());
  }
};

export const fetchUserProfile = () => async (dispatch: AppDispatch, getState: () => AppState) => {
  const {
    user: { userProfileLoadingState },
    auth: { serviceUrl, accessToken },
  } = getState();

  if (userProfileLoadingState.isDataFetching) {
    return;
  }

  dispatch(setDataFetchingStarted('userProfileLoadingState'));

  try {
    const { data } = await axios.get<IUserProfile>(`${serviceUrl}/auth/profile`, {
        headers: { Authorization: accessToken },
    });

    const userInfo = {
      firstName: data.first_name,
      lastName: data.last_name,
      userPrincipalName: data.user_principal_name,
      teamsLink: data.teams_link,
      jobTitle: data.job_title,
      email: data.email
    };
       
    dispatch(updateUserProfile(userInfo));
  } catch (err: any) {
    console.error(err.message);
    dispatch(setDataFetchingEnded('userProfileLoadingState'));
  }
};

export const fetchOrgChart = () => async (dispatch: AppDispatch, getState: () => AppState) => {
  const {
    user: { orgChartDataLoadingState },
    auth: { serviceUrl, accessToken },
  } = getState();

  if (orgChartDataLoadingState.isDataFetching) {
    return;
  }

  try {
    dispatch(setDataFetchingStarted('orgChartDataLoadingState'));
    
    const { data } = await axios.get<Record<string, IUserProfile[] | IUserProfile>>(`${serviceUrl}/auth/org`, {
      headers: { Authorization: accessToken },
    });
    
    const mappedData = Object.fromEntries(
      Object.entries(data).map(([title, userInfo]) => [
        title, 
        ((((userInfo as IUserProfile[]).length > 0) ? userInfo : [userInfo]) as IUserProfile[]).map(({
          first_name: firstName,
          last_name: lastName,
          user_principal_name: userPrincipalName,
          teams_link: teamsLink,
          job_title: jobTitle,
          email
        }) => ({
          firstName,
          lastName,
          userPrincipalName,
          teamsLink,
          jobTitle,
          email
        }))
      ])
    );

    dispatch(populateOrgChartData(mappedData));
  } catch (err: any) {
    console.error(err.message);
    dispatch(setDataFetchingEnded('orgChartDataLoadingState'));
  }
};
