import resourcesAndToolsImg from '../../images/resources_and_tools.jpg';
import resourcesAndToolsPh from '../../images/resources_and_tools_ph.png';
import { DashboardButton } from './DashboardButton';

export const ResourcesAndToolsButton = () => (
  <DashboardButton
    {...{
      size: 'sm',
      link: '/resources-tools',
      label: 'Resources & Tools',
      image: resourcesAndToolsImg,
      placeholder: resourcesAndToolsPh,
      aspectRatio: 1.65,
    }}
  />
);
