import axios from 'axios';
import { add, differenceInMilliseconds, fromUnixTime } from 'date-fns';
import jwt_decode from 'jwt-decode';
import {
  LOCAL_STORAGE_AUTH_TOKENS,
  LOCAL_STORAGE_REMEMBER_PATH_TO_RETURN,
} from '../../config/localStorageKeys';
import type { AppDispatch, AppState } from '../store';
import type { AuthTokens } from './auth.types';
import { logout, propagateError, registerToken, startObtainingToken } from './authSlice';

export const obtainLogInStatus =
  (rememberPathname: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const { isLoggedIn, isObtainingAccessTokenInProgress, serviceUrl, ssoUrl } = getState().auth;

    if (isLoggedIn || isObtainingAccessTokenInProgress) {
      return;
    }

    let authTokens: AuthTokens | null = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_AUTH_TOKENS) ?? 'null'
    );

    const possibleRememberedPath = localStorage.getItem(LOCAL_STORAGE_REMEMBER_PATH_TO_RETURN);

    if (possibleRememberedPath == null) {
      localStorage.setItem(LOCAL_STORAGE_REMEMBER_PATH_TO_RETURN, rememberPathname);
    }

    if (authTokens == null) {
      const possibleAuthCode = new URLSearchParams(window.location.search).get('code');
      
      if (possibleAuthCode != null) {
        dispatch(startObtainingToken(possibleRememberedPath));
        
        try {
          ({ data: authTokens } = await axios.post<AuthTokens>(`${serviceUrl}/auth/sso/tokens`, {
            code: possibleAuthCode,
          }));
        } catch {
          localStorage.removeItem(LOCAL_STORAGE_REMEMBER_PATH_TO_RETURN);
          dispatch(propagateError());

          return;
        }

        localStorage.setItem(LOCAL_STORAGE_AUTH_TOKENS, JSON.stringify(authTokens));
      } else {
        window.location.assign(ssoUrl);

        return;
      }
    }

    localStorage.removeItem(LOCAL_STORAGE_REMEMBER_PATH_TO_RETURN);
    const { access_token: accessToken } = authTokens;
    dispatch(registerToken(accessToken));
    const expirationDate = fromUnixTime(jwt_decode<{ exp: number }>(accessToken).exp);

    setTimeout(
      () => {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKENS);
        dispatch(logout());
      },

      Math.max(differenceInMilliseconds(add(expirationDate, { minutes: -1 }), new Date()), 0)
    );
  };
