import { RolePanel } from "../RolePanel";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { selectDirectorsList, selectTeamList, selectUserProfile } from "../../redux/user";
import { useAppSelector } from "../../redux/store";
import Grid from '@mui/material/Grid';

const VerticalDivider = styled(Divider)({
    border: '2px solid rgba(184, 210, 224, 1)', 
    height: '32px', 
    marginTop: '12px',
    marginBottom: '12px',
    width: '2px',
    marginLeft: '50%'
});

/**
* Organization chart for "Get Connected" page
*/
export const OrgChart = () => {
    const directorsList = useAppSelector(selectDirectorsList);
    const teamList = useAppSelector(selectTeamList);
    const userProfile = useAppSelector(selectUserProfile);
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            {directorsList && 
                directorsList.length > 0 && 
                    directorsList.map((v, i) => (
                        <Grid key={`directorGrid:${i}`}>
                            <RolePanel key={`directorPanel:${i}`}
                                header={i === Object.keys(directorsList).length - 1 ? 'Meet your Manager!' : null}
                                name={`${v.firstName} ${v.lastName}`} 
                                role={v.jobTitle!}
                                email={v.email!}
                                teamLink={v.teamsLink!}/>
                            {(Object.keys(directorsList).length - 1 !== i || teamList.length > 0) && 
                                (<VerticalDivider key={i} orientation='vertical'/>)
                            }
                        </Grid>)
                    )
            }
            
            {teamList && 
                teamList.length > 0 && (<Grid container>
                    {teamList && 
                        teamList.length > 0 && 
                            teamList.map((v, i) => (
                                <Grid container item justifyContent={i%2 === 0 ? 'flex-end' : 'flex-start'} xs={6} key={`teamGrid:${i}`} p={1}>
                                    <RolePanel key={`teamPanel:${i}`}
                                        name={`${v.firstName} ${v.lastName}`} 
                                        role={v.jobTitle!}
                                        email={v.email!}
                                        teamLink={v.teamsLink!}
                                        sx={v.email! === userProfile?.email ? { border: '2px solid #005F90' } : {}}/>
                                </Grid>)
                            )
                    }
                </Grid>)
            }
        </Box>
    )
};
