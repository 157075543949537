import { Button, Grid, styled } from '@mui/material';
import { Normalize, PartialExtraProperties } from '../../helpers/typeHelpers';

type ButtonProps = Normalize<Parameters<typeof Button>[0]>;

export const ButtonLikeGrid = styled((props: ButtonProps) => (
  <Grid item {...{ component: Button, height: 44, fontSize: '1rem' }} {...props} />
))(({ variant }) => ({
  color: 'primary.main',

  ...(variant === 'outlined' && {
    border: '2px solid rgba(0, 0, 0, 0.48)',
    color: 'rgba(0, 0, 0, 0.48)',
  }),

  '&:hover': variant === 'outlined' ? { border: '2px solid rgba(0, 0, 0, 0.65)' } : null,

  '&.Mui-disabled':
    variant === 'contained'
      ? { color: '#FFFFFF', backgroundColor: '#C2C2C2' }
      : variant === 'outlined'
      ? { border: '2px solid #C2C2C2' }
      : undefined,
}));

type HrefOrOnClick = PartialExtraProperties<
  Pick<ButtonProps, 'href'> | { onClick: React.MouseEventHandler<HTMLButtonElement> }
>;

type GenericButtonProps = Normalize<
  Pick<ButtonProps, 'children' | 'disabled' | 'variant'> & HrefOrOnClick
>;

/**
 * Button for flexbox occupying the whole width
 */
export const WideButton = ({ variant = 'contained', ...restProps }: GenericButtonProps) => (
  <ButtonLikeGrid {...{ variant, ...restProps }} {...{ color: 'primary' }} fullWidth />
);

const noBorderStyle = { '&, &:hover, &.Mui-disabled': { border: 'none' } };

/**
 * Button with no outline for flexbox occupying the whole width
 */
export const WideButtonWhite = (
  props: Normalize<Pick<ButtonProps, 'disabled' | 'children'> & HrefOrOnClick>
) => (
  <ButtonLikeGrid
    {...props}
    {...{
      variant: 'outlined',

      sx: {
        color: 'primary.main',
        boxShadow: `0px 0px 8px rgba(63, 108, 150, 0.48)`,
        ...noBorderStyle,
        '&.Mui-disabled': { color: 'rgba(63, 108, 150, 0.32)' },
      },
    }}
    fullWidth
  />
);

/**
 * Button for flexbox occupying a half of the width
 */
export const HalfButton = ({ variant = 'contained', ...restProps }: GenericButtonProps) => (
  <ButtonLikeGrid {...{ variant, ...restProps }} {...{ flexGrow: 1, width: '35%' }} />
);

/**
 * Button with no outline for flexbox occupying a half of the width
 */
export const HalfButtonBorderless = ({
  href,
  onClick,
  ...restProps
}: Omit<GenericButtonProps, 'variant'>) => (
  <HalfButton
    {...(onClick ? { onClick } : { href })}
    {...restProps}
    {...{
      variant: 'outlined',

      sx: {
        color: 'primary.main',
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.24)',
        ...noBorderStyle,
      },
    }}
  />
);
