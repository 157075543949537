import { Link } from '@mui/material';
import { SxProps } from '@mui/material/styles';

/**
* Component for links that open in new tab with proper security measures
*/
export const LinkComponent = ({
    href,
    header,
    sx,
    children
}:{
    /**
    * Link to access
    */
    href: string;
    /**
    * Boolean for header styling
    */
    header?: boolean;
    /**
    * Custom Sx styling
    */
    sx?: SxProps;
    /**
    * Wording to be displayed as link
    */
    children: string
}) => {
    return <Link href={href} underline="none" target="_blank" rel="noreferrer" sx={{ ...(header ? { fontSize: '12px', fontWeight: 600 } : null), ...sx }}>{children}</Link>
}