import axios from "axios"
import { batch } from "react-redux"
import { OnboardingTasksData, Section } from "../../shared/services/portalService.types"
import { AppDispatch, AppState } from "../store"
import { SectionId, TaskId } from "./task.types"
import { populateTasks, markItem, updateRemoteState, updateLocalToMatchRemote, populateRefresh, populateCheckCompleted, flagFetching, propagateError, openCongratulations, disableAllBoxes } from "./taskSlice"

export const checkComplete = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const { 
        task: { checkCompleteLoadingState },
        auth: { serviceUrl, accessToken }, 
    } = getState()

    if (checkCompleteLoadingState.dataFetching) {
        return
    }

    dispatch(flagFetching('checkCompleteLoadingState'))

    try {
        const response = await axios.get<{ complete: boolean }>(
            `${serviceUrl}/complete`,
            { headers: { Authorization: accessToken } },
        )

        dispatch(propagateError({ key: 'checkCompleteLoadingState', errorState: false }))
        dispatch(populateCheckCompleted(response.data.complete))
    } catch (err) {
        dispatch(propagateError({key: 'checkCompleteLoadingState', errorState: true}))
    }
}

export const fetchOnboardingTasks = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const {
        task: { tasksLoadingState },
        auth: { serviceUrl, accessToken }
    } = getState()

    if (tasksLoadingState.dataFetching) {
        return
    }

    const mapper = (data: Section[]) => {
        return data.map(({
            id,
            checked,
            section_name,
            description,
            tooltip,
            refresh,
            tasks
        }) => ({
            id,
            checked,
            sectionName: section_name,
            description,
            tooltip,
            refresh,
            tasks: tasks?.map(({
                id,
                task_name,
                checkable,
                checked,
                link,
                refresh,
                subtasks,
            }) => ({
                id,
                taskName: task_name,
                checkable,
                checked,
                link,
                refresh,
                subtasks: subtasks?.map(({
                    id,
                    task_name,
                    checkable,
                    checked,
                    link,
                    refresh,
                }) => ({
                    id,
                    taskName: task_name,
                    checkable,
                    checked,
                    link,
                    refresh,
                })),
            }))
        }))
    }
    
    dispatch(flagFetching('tasksLoadingState'))

    try {
        const response = await axios.get<OnboardingTasksData>(
            `${serviceUrl}/tasks`,
            { headers: { Authorization: accessToken } },
        )

        dispatch(propagateError({ key: 'tasksLoadingState', errorState: false}))
        dispatch(populateTasks({
                dayOneTasks: mapper(response.data['Day 1']),
                weekOneTasks: mapper(response.data['Week 1']),
                futureTasks: mapper(response.data['Future'])
            }))
    } catch (err) {
        dispatch(propagateError({ key: 'tasksLoadingState', errorState: true}))    
    }
}

export const confirmComplete = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const {
        task: { confirmLoadingState },
        auth: { serviceUrl, accessToken }
    } = getState()

    if (confirmLoadingState.dataFetching) {
        return
    }

    dispatch(flagFetching('confirmLoadingState'))

    try {
        await axios.post(
            `${serviceUrl}/complete`,
            null,
            { headers: { Authorization: accessToken } }
        )
        batch(() => {
            dispatch(propagateError({key: 'confirmLoadingState', errorState: false}))
            dispatch(populateCheckCompleted(true))
            dispatch(disableAllBoxes())
            dispatch(openCongratulations(true))
        })
    } catch (err) {
        dispatch(propagateError({key: 'confirmLoadingState', errorState: true}))
    }
}

export const sendCheckTask = (sectionId: SectionId, taskId?: TaskId, onLast?: boolean) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const {
        task: { sendTaskLoadingState },
        auth: { serviceUrl, accessToken }
    } = getState()

    dispatch(markItem({ sectionId, ...(taskId ? { taskId } : null) }))

    if (sendTaskLoadingState.dataFetching) {
        return
    }

    dispatch(flagFetching('sendTaskLoadingState'))

    let differences: { id: string, checked: boolean }[] = []

    const loop = async () => {
        const { taskRefMap, sectionRefMap } = getState().task

        Object.values({...taskRefMap, ...sectionRefMap})
        .filter((item) => item.state.local !== item.state.remote)
        .map((item) => ({id: item.id, checked: item.state.local}))
        .forEach((item) => differences.push(item))

        if (differences.length) {
            try {
                const response = await axios.put<{id: string, checked: boolean}[]>(
                    `${serviceUrl}/tasks`,
                    differences,
                    { headers: { Authorization: accessToken } }
                )
                if (!response.data.length) {
                    throw new Error('err')
                }
                batch(() => {
                    dispatch(propagateError({ key: 'sendTaskLoadingState', errorState: false }))
                    dispatch(updateRemoteState({data: response.data}))
                })
                differences = []
                loop()
            } catch (err) {
                dispatch(propagateError({ key: 'sendTaskLoadingState', errorState: true }))
                dispatch(updateLocalToMatchRemote())
            }
        } else {
            dispatch(propagateError({ key: 'sendTaskLoadingState', errorState: false }))
            if (onLast) {
                dispatch(confirmComplete())
            }
        }
    }
    loop()
}

export const fetchRefresh = (sectionId: SectionId) => async (dispatch: AppDispatch,  getState: () => AppState) => {
    const {
        task: { refreshLoadingState },
        auth: { serviceUrl, accessToken }
    } = getState()

    if (refreshLoadingState.dataFetching) {
        return
    }

    dispatch(flagFetching('refreshLoadingState'))

    try {
        const response = await axios.put(
            `${serviceUrl}/refresh`,
            {section_id: sectionId},
            { headers: { Authorization: accessToken } }
        )
         
        dispatch(propagateError({ key: 'refreshLoadingState', errorState: false }))
        dispatch(populateRefresh({data: response.data}))
    } catch (err) {
        dispatch(propagateError({ key: 'refreshLoadingState', errorState: true }))
    }
}