import { Grid } from '@mui/material';
import { ImageWithPlaceholder } from '../components/common/ImageWithPlaceholder';
import { OurJourney } from '../components/who-we-are/OurJourney';
import { OurPurpose } from '../components/who-we-are/OurPurpose';
import { Mission, Vision } from '../components/who-we-are/VisionMission';
import { YourVoice } from '../components/who-we-are/YourVoice';
import { YouSaid } from '../components/who-we-are/YouSaid';
import ourValuesImg from '../images/ourvalues.jpg';
import ourValuesPh from '../images/ourvalues_ph.png';

/**
 * Who We Are page
 */
export const WhoWeAre = () => {
  return (
    <Grid container direction="column" gap={2} bgcolor="#FFFFFF">
      <OurPurpose />

      <Grid container gap={2} flexWrap="nowrap">
        <Vision />
        <Mission />
      </Grid>

      <ImageWithPlaceholder
        {...{
          src: ourValuesImg,
          alt: 'Our Values',
          placeholder: ourValuesPh,
          aspectRatio: 3.6,
          sx: { width: '100%', backgroundColor: '#022b40' },
        }}
      />

      <Grid container>
        <OurJourney />
        <YourVoice />
        <YouSaid />
      </Grid>
    </Grid>
  );
};
