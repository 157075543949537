import { Box, type SxProps } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

export const ImageWithPlaceholder = ({
  src,
  alt,
  placeholder,
  aspectRatio,
  sx,
}: {
  src: string;
  alt: string;
  placeholder: string;
  aspectRatio: number;
  sx?: SxProps & { height?: never };
}) => {
  const pt = useMemo(() => {
    const percentsBase =
      sx && 'width' in sx && typeof sx.width === 'string' && /^\d+%$/.test(sx.width)
        ? Number.parseFloat(sx.width.match(/.*(?=%$)/)![0])
        : 100;

    return `${percentsBase / aspectRatio}%`;
  }, [aspectRatio, sx]);

  const { imageRef: phRef, isImageLoaded: isPhLoaded } = useImageLoaded();
  const { imageRef: mainImageRef, isImageLoaded: isMainImageLoaded } = useImageLoaded();

  return (
    <>
      {isPhLoaded && (
        <Box
          {...{
            ref: mainImageRef,
            component: 'img',
            src,
            alt,
            sx,
            hidden: !isMainImageLoaded,
          }}
        />
      )}

      {!isMainImageLoaded && (
        <Box {...{ position: 'relative', overflow: 'hidden', sx: { ...sx, pt } }}>
          <Box
            {...{
              ref: phRef,
              component: 'img',
              src: placeholder,
              alt,
              position: 'absolute',
              width: '100%',
              top: 0,
              sx: { imageRendering: 'pixelated' },
            }}
          />
        </Box>
      )}
    </>
  );
};

const useImageLoaded = () => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isImageLoaded, setImageLoaded] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isImageLoaded) {
      return;
    }

    imageRef.current?.complete && setImageLoaded(true);

    if (imageRef.current && !imageRef.current.onload) {
      imageRef.current.onload = () => setImageLoaded(true);
    }
  });

  return { imageRef, isImageLoaded };
};
