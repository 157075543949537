import { PanelWithHeader } from "../PanelWithHeader"
import { RowItem } from "./RowItem"
import { LinkComponent } from "./LinkComponent"

/**
 * Explore Our Tools panel on Resources and Tools page
 */
export const ExploreOurTools = () => {
    return <PanelWithHeader header="Explore Our Tools" sx={{ maxWidth: '632px' }}>
        <RowItem>
            <LinkComponent header href="https://allieduniversalservices.sharepoint.com/sites/Applications">Applications</LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://allieduniversalservices.sharepoint.com/sites/Directory">Departmental Sites</LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://my.aus.com">my.aus.com</LinkComponent>
        </RowItem>

        <RowItem>
            <LinkComponent header href="https://pie.aus.com/custompage">Promote From Within Culture</LinkComponent>
        </RowItem>
    </PanelWithHeader>
}