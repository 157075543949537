import { Grid, Tooltip, Typography } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps } from '@mui/material/styles';
import React from "react";

/**
* Panel component with header and children to be displayed within the body
*/

export const PanelWithHeader = ({
    header, 
    subHeader,
    children, 
    tasksCompleted, 
    totalTasks,
    tooltipMessage, 
    sx
}: {
    /**
    * String to be displayed in the header of the component
    */
    header: string, 
    /**
     * Secondary unbolded string to be displayed in the header 
     */
    subHeader?: string,
    /**
    * Elements to be displayed within the panel itself
    */
    children: React.ReactNode, 
    /**
    * Optional total number of completed tasks
    */
    tasksCompleted?: number, 
    /**
    * Optional total number of tasks
    */
    totalTasks?: number, 
    /**
     * Optional message to show in tooltip
     */
    tooltipMessage?: string,
    /**
    * Custom styling using sx
    */
    sx?: SxProps
}) => {
    return (
        <Grid 
        container
        {...{
            sx: {
                borderRadius: '4px',
                boxShadow: '0px 1px 8px rgba(24, 67, 105, 0.2)',
                bgcolor: '#FFFFFF',
                flexDirection: 'column',
                ...sx,
            }
        }}>
            <Grid container justifyContent="space-between" bgcolor="#005F90" p="8px 12px" height="40px" borderRadius="4px 4px 0px 0px">
                <Typography color="#F8F8F8" fontWeight="700">
                    {header} {subHeader && <Typography component="span" fontWeight="400">{subHeader}</Typography>}
                </Typography>

                {
                tooltipMessage ?
                    <Tooltip
                        placement="top"
                        title={tooltipMessage} 
                        arrow
                        componentsProps={{ 
                            tooltip: { 
                                sx: { 
                                    bgcolor: '#FFFFFF', 
                                    color: '#000000',
                                    boxShadow: '0px 1px 8px 1px rgba(24, 67, 105, 0.2)',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    maxWidth: '388px',
                                    } 
                            },
                            arrow: {
                                sx: {
                                    '&::before': { bgcolor: '#FFFFFF', boxShadow: '0px 1px 8px 1px rgba(24, 67, 105, 0.2)' }
                                }
                            }
                            }} 
                    >
                        <InfoOutlinedIcon sx={{ color: '#FFFFFF' }}/>
                    </Tooltip>
                :
                tasksCompleted != null && totalTasks != null && tasksCompleted <= totalTasks && totalTasks !== 0 &&
                    <Typography color="#F8F8F8">
                        Completed {tasksCompleted}/{totalTasks}
                    </Typography>
                }
            </Grid>
            {children}
        </Grid>
    )
}