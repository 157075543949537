import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AppFooter, AppHeader } from '../navigation';
import { useResetScroll } from './useResetScroll';

export const LandingPageTemplate = () => {
  const mainContainerRef = useResetScroll();
  const { pathname } = useLocation();

  return (
    <>
      <AppHeader />

      <Box
        {...{
          ref: mainContainerRef,
          position: 'absolute',
          width: '100%',
          top: 72,
          bottom: 56,
          overflow: 'auto',
          sx: { background: 'linear-gradient(#EBF2F5, #49ABD8)' },
        }}>
        <Outlet />
      </Box>

      <AppFooter />

      {pathname === '/' && (
        <Button
          {...{
            component: Link,
            to: '/welcome',
            startIcon: <PlayCircleOutlineIcon {...{ sx: { width: '24px', height: '24px' } }} />,

            sx: {
              position: 'absolute',
              bottom: '38px',
              left: '24px',
              right: 'auto',
              height: '48px',
              fontSize: '16px',
              fontWeight: 700,
              bgcolor: '#ffff',
              px: '15px',
              '&:hover': { backgroundColor: '#ffff' },
            },
          }}>
          Welcome Message from CEO Steve Jones
        </Button>
      )}
    </>
  );
};
